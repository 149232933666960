























































import {
  computed,
  defineComponent,
  h,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  Ref,
  watch,
} from '@vue/composition-api'
import dayjs from 'dayjs'
import { LoaderComponent, PluginApi } from 'vue-loading-overlay'
import MissionRankingListSection from '@/components/MissionPage/MissionRankingPane/MissionRankingListSection.vue'
import MissionRankingHeaderSection from '@/components/MissionPage/MissionRankingPane/MissionRankingHeaderSection.vue'
import MissionRankingShareSection from '@/components/MissionPage/MissionRankingPane/MissionRankingShareSection.vue'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import {
  PointRankingType,
  RankingImageInfoType,
  ShareRankingImageType,
} from '@/store/stores/pageStore/MissionPage/MissionPageStore'
import { RankInUserType } from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import useMissionRanking from '@/components/MissionPage/MissionRankingPane/hook/useMissionRanking'
import FileConverter from '@/util/fileConverter/FileConverter'
import DeviceInfo from '@/util/DeviceInfo'
import useDownload from '@/util/download/useDownload'
import Logger from '@/util/logger/Logger'
import useX from '@/components/hook/sns/useX'
import LoadingOverLayStore, {
  ProgressMessageType,
} from '@/store/stores/pageStore/common/LoadingOverlayProgressStore'
import useXAuthentication from '@/components/hook/sns/useXAuthentication'
import LoadingOverlayProgressSection from '@/components/sections/LoadingOverlay/LoadingOverlayProgressSection.vue'
import useHistory from '@/store/hook/useHistory'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import IndexedDBAccessor from '@/store/stores/IndexedDBstore/IndexedDBAccessor'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/* eslint-disable @typescript-eslint/no-var-requires */
const bgImagePath = require('@/assets/img/MissionPage/bg.png')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const commonBackgroundImagePath = require('@/assets/img/MissionPage/ranking/bg_share_card.png')

export default defineComponent({
  name: 'MissionRankingPane',
  components: {
    MissionRankingShareSection,
    MissionRankingHeaderSection,
    MissionRankingListSection,
  },
  setup() {
    const missionPageStore = StoreUtil.useStore('MissionPageStore')
    const { getTargetRankingUserImage, createRankingImage, getOwnRankingData } = useMissionRanking()
    const {
      loadFile,
      downloadFile,
      removeFile,
      downloadFileForCordova,
      createTemporaryFileForCordova,
    } = useDownload()
    const userStore = StoreUtil.useStore('UserStore')
    const { user } = userStore
    const { saveMissionHistory } = useHistory()
    const { getDisplayUserName } = useMissionRanking()
    const loading = inject('loading') as PluginApi
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const {
      fetchPointRankingPageData,
      pointRankings,
      aggregationDate,
      retrieveNameUsersByUserId,
      rankingUserImageData,
    } = missionPageStore
    const { ownOrganization } = contractInfoStore

    const { startXAuthentication, availableXAuthentication } = useXAuthentication()

    const state = reactive({
      scrollY: 0,
    })

    const header = ref<HTMLDivElement | null>(null)

    const headerFixed = ref(false)

    const shareRankingImageView = ref(false)

    // SNS投稿モーダルを表示するかどうか
    const visiblePostSnsModal = ref(false)

    // SNS投稿用の画像のURL
    const shareImageUrl = ref('')

    // SNS投稿用の画像の表示に必要なデータ
    const shareRankingImageInfo = ref({}) as Ref<ShareRankingImageType>

    // SNS投稿用の画像の背景画像
    const backgroundImage = ref({}) as Ref<RankingImageInfoType | null | undefined>
    // SNS投稿用の画像の背景画像パス
    const backgroundImagePath = ref('')
    // ランキング一覧を表示した際に、初期表示するランキング確定ずみのデータ一覧
    const initDisplayPointRanking = ref([]) as Ref<PointRankingType[]>

    const bodyOffsetSize = computed(() => {
      if (!headerFixed.value) {
        return 0
      }
      return header.value?.clientHeight || 0
    })

    const handlePaneScroll = (event: Event) => {
      const paneElm = event.target as HTMLElement
      state.scrollY = paneElm?.scrollTop
    }

    /**
     * 使用した、createObjectURLを解放する
     */
    const revokeRankingImageObjectURL = () => {
      if (backgroundImagePath.value === commonBackgroundImagePath)
        URL.revokeObjectURL(backgroundImagePath.value)
    }

    /**
     * SNS投稿モーダルを閉じる
     */
    const closeSnsPostModal = () => {
      const { setDisplayedConfirmMissionRanking } = IndexedDBAccessor()
      // 初期表示したデータをリストから削除
      if (initDisplayPointRanking.value.length > 0) {
        const index = initDisplayPointRanking.value.findIndex(
          (ranking) => ranking.key === shareRankingImageInfo.value.ranking.key,
        )
        if (index !== -1) {
          const targetPointRanking = initDisplayPointRanking.value[index]
          const formatString =
            shareRankingImageInfo.value.ranking.type === 'SEASON' ? 'YYYY' : 'YYYYMM'
          const targetRankingValue = dayjs(targetPointRanking.aggregationStartDate)
            .tz('Asia/Tokyo')
            .format(formatString)

          setDisplayedConfirmMissionRanking(
            shareRankingImageInfo.value.ranking.type === 'SEASON'
              ? 'DISPLAYED_CONFIRM_SEASON_RANKING'
              : 'DISPLAYED_CONFIRM_MONTHLY_RANKING',
            Number(targetRankingValue),
          )
          initDisplayPointRanking.value.splice(index, 1)
        }
      }
      shareRankingImageInfo.value = {} as ShareRankingImageType
      shareRankingImageView.value = false
      revokeRankingImageObjectURL()
      backgroundImage.value = {} as RankingImageInfoType
    }

    /**
     * 背景画像を取得する
     */
    const getBackGroundImagePath = async (
      targetBackgroundImage: RankingImageInfoType | null | undefined,
    ) => {
      if (targetBackgroundImage) {
        const accept = 'image/*'
        const result = await loadFile(
          CloudFrontUtil.getSignedUrl(targetBackgroundImage.imagePath),
          {
            responseType: 'blob',
            headers: {
              accept,
            },
          },
        )
        const blob = new Blob([result?.data], { type: accept })
        return URL.createObjectURL(blob)
      }
      return commonBackgroundImagePath
    }

    /**
     * SNS投稿モーダルを開く
     */
    const openSnsShareModal = async (
      userData: RankInUserType,
      targetPointRanking: PointRankingType,
    ) => {
      shareRankingImageInfo.value = {
        displayName: getDisplayUserName(retrieveNameUsersByUserId.value, userData),
        point: userData.point,
        ranking: {
          key: targetPointRanking.key,
          type: targetPointRanking.rankingType,
          rank: userData.rank,
          title: targetPointRanking.postImageSNS.title,
          headline: targetPointRanking.postImageSNS.headline,
          mothDate: targetPointRanking.postImageSNS.imageMothDate,
        },
      }
      // 背景画像を設定する
      backgroundImage.value = getTargetRankingUserImage(
        shareRankingImageInfo.value,
        rankingUserImageData.value,
        targetPointRanking.aggregationStartDate,
      )
      backgroundImagePath.value = await getBackGroundImagePath(backgroundImage.value)

      shareRankingImageView.value = true
    }

    onUnmounted(() => {
      revokeRankingImageObjectURL()
    })

    watch(
      () => state.scrollY,
      () => {
        headerFixed.value = state.scrollY > 10
      },
    )

    /**
     * SNS投稿モーダルの初期表示リストを監視する
     */
    watch(
      () => initDisplayPointRanking.value,
      (value) => {
        if (value.length > 0) {
          // 初期表示リストが複数ある際に、実行される処理
          let userData: RankInUserType | undefined

          // eslint-disable-next-line no-restricted-syntax
          for (const v of value) {
            userData =
              getOwnRankingData(v, ownOrganization.value)?.selfData ||
              v.rankings.find((rank) => rank.userId === LoginStore.value.userId)

            if (userData) break
          }

          if (userData) {
            openSnsShareModal(userData, value[0])
          }
        }
      },
    )

    onMounted(async () => {
      const loader = loading.show()
      try {
        await fetchPointRankingPageData()
        loader.hide()

        // 初期表示リストを取得
        const targetPointRankings = pointRankings.value.filter(
          (pointRanking) => pointRanking.isInitialDisplay,
        )

        // 初期表示リストを設定
        initDisplayPointRanking.value.push(...targetPointRankings)
      } catch (e) {
        loader.hide()
        MessageDialogStore.value.open({
          title: I18n.tc('MissionPage.RankingPage.errors.fetchPointRankingDataError.title'),
          message: I18n.tc('MissionPage.RankingPage.errors.fetchPointRankingDataError.message'),
        })
      }
    })

    // SNS投稿中に使用するローダー
    let postSnsLoader = null as LoaderComponent | null
    const LoadingOverlayProgressSectionComponent = h(LoadingOverlayProgressSection)
    // SNS投稿画像のbase64データ：X認可後に投稿する際に使用
    let tempPostSnsImageBase64 = ''
    // SNS投稿画像のコメントデータ：X認可後に投稿する際に使用
    let tempPostSnsComment = ''
    // X認可中かどうか
    let isXAuthenticating = false

    /**
     * XにSNS投稿画像を投稿する
     */
    const postX = async (
      oAuthToken: string,
      oAuthSecretToken: string,
      comment: string,
      base64: string,
    ) => {
      const xAPI = useX(process.env.VUE_APP_X_OAUTH_API_KEY, process.env.VUE_APP_X_OAUTH_API_SECRET)
      try {
        // 画像ファイルをアップロードする
        const mediaId = await xAPI.uploadFile(oAuthToken, oAuthSecretToken, base64)
        LoadingOverLayStore.value.updateProgressList('postX', 50)
        // ツイートする
        const result = await xAPI.tweet(
          oAuthToken,
          oAuthSecretToken,
          // ハッシュタグを付けて投稿する
          `${comment}\n${process.env.VUE_APP_X_HASH_TAG}`,
          mediaId,
        )
        LoadingOverLayStore.value.updateProgressList('postX', 100)
        Logger.info(`Success to tweet. tweet.id:${result.id}, mediaId: ${mediaId}`)
        return true
      } catch (e) {
        Logger.error(`Failed to tweet. e: ${JSON.stringify(e)}`)
        return false
      }
    }

    /**
     * SNS投稿ミッションのログを登録する
     */
    const savePostSnsMissionHistory = () => {
      saveMissionHistory(user.value.id || '', 'manage_user', 'post_on_sns')
    }

    /**
     * X認可後にSNS投稿を行うためのデータを設定する
     */
    const setTempPostSnsData = (base64: string, comment: string) => {
      tempPostSnsImageBase64 = base64
      tempPostSnsComment = comment
      isXAuthenticating = true
    }

    /**
     * X認可後にSNS投稿を行った際に使用したデータをクリアする
     */
    const clearTempPostSnsData = () => {
      tempPostSnsImageBase64 = ''
      tempPostSnsComment = ''
      isXAuthenticating = false
    }

    /**
     * XにSNS投稿画像を投稿する
     */
    const postSns = async (
      base64: string,
      comment: string,
      isPostX: boolean,
      isPostInstagram: boolean,
    ) => {
      const hasAvailableXAuthentication = availableXAuthentication()
      if (!hasAvailableXAuthentication) {
        setTempPostSnsData(base64, comment)
        LoadingOverLayStore.value.setCautionMessage(
          I18n.tc('RaceVideoPage.highlights.sns.xAndInstagram.xAuthenticationCaution'),
        )
        LoadingOverLayStore.value.setButtonLabel(
          I18n.tc('RaceVideoPage.highlights.sns.xAndInstagram.xAuthenticationButtonLabel'),
        )
        postSnsLoader = loading.show(
          { opacity: 0.8 },
          { after: LoadingOverlayProgressSectionComponent },
        )
        await startXAuthentication()
        return
      }

      if (
        !hasAvailableXAuthentication ||
        !contractInfoStore.ownOrganization.value?.xAuthentication
      ) {
        MessageDialogStore.value.open({
          title: I18n.tc('MissionPage.RankingPage.errors.snsAuthorizationErrorForX.title'),
          message: I18n.tc('MissionPage.RankingPage.errors.snsAuthorizationErrorForX.message'),
        })
        Logger.info('MissionRankingShareSection#onClickPost: Failed to post x for authentication.')
      } else {
        const displayProgressList: Array<ProgressMessageType> = []
        if (isPostX) {
          displayProgressList.push({
            progressId: 'postX',
            message: 'RaceVideoPage.highlights.progress.postX',
            progress: 0,
          })
        }
        if (isPostInstagram) {
          displayProgressList.push({
            progressId: 'postInstagram',
            message: 'RaceVideoPage.highlights.progress.postInstagram',
            progress: 0,
          })
        }
        LoadingOverLayStore.value.setProgressList(displayProgressList)
        postSnsLoader = loading.show(
          { opacity: 0.8 },
          { after: LoadingOverlayProgressSectionComponent },
        )

        if (isPostX) {
          const result = await postX(
            contractInfoStore.ownOrganization.value.xAuthentication.oauthToken,
            contractInfoStore.ownOrganization.value.xAuthentication.oauthTokenSecret,
            comment,
            base64,
          )
          if (!result) {
            postSnsLoader.hide()
            MessageDialogStore.value.open({
              title: I18n.tc('MissionPage.RankingPage.errors.snsPostErrorForX.title'),
              message: I18n.tc('MissionPage.RankingPage.errors.snsPostErrorForX.message'),
            })
            Logger.info('MissionRankingShareSection#onClickPost: Failed to post x.')
            return
          }
          savePostSnsMissionHistory()
        }
        MessageDialogStore.value.open({
          title: I18n.tc('MissionPage.RankingPage.success.snsPost.title'),
          message: I18n.tc('MissionPage.RankingPage.success.snsPost.message'),
        })
        postSnsLoader.hide()
        visiblePostSnsModal.value = false
      }
    }

    /**
     * X認可後にSNS投稿を行うため、組織情報Xトークン取得日を監視する。
     */
    watch(
      () => contractInfoStore.ownOrganization.value?.xAuthentication?.acquisitionDate,
      () => {
        const result = availableXAuthentication()
        if (result && isXAuthenticating && tempPostSnsComment && tempPostSnsImageBase64) {
          postSnsLoader?.hide()
          LoadingOverLayStore.value.clearLoadingOverLayData()
          postSns(tempPostSnsImageBase64, tempPostSnsComment, true, false)
        }
      },
    )

    /**
     * X認可中ローダーの中断ボタンの状態を監視し、中断された際に、中断処理を実施する。
     */
    watch(
      () => LoadingOverLayStore.value.state.pressedButton,
      (value: boolean) => {
        if (value) {
          clearTempPostSnsData()
          postSnsLoader?.hide()
        }
      },
    )

    return {
      state,
      header,
      bodyOffsetSize,
      bgImagePath,
      headerFixed,
      pointRankings,
      aggregationDate,
      retrieveNameUsersByUserId,
      handlePaneScroll,
      closeSnsPostModal,
      openSnsShareModal,
      shareRankingImageView,
      rankingUserImageData,
      shareRankingImageInfo,
      visiblePostSnsModal,
      shareImageUrl,
      createRankingImage,
      backgroundImage,
      backgroundImagePath,
      downloadFile,
      downloadFileForCordova,
      loading,
      getDisplayUserName,
      getTargetRankingUserImage,
      getBackGroundImagePath,
      removeFile,
      createTemporaryFileForCordova,
      postSns,
      savePostSnsMissionHistory,
    }
  },
  methods: {
    /**
     * OS標準の共有モーダルを表示する
     */
    async openOsShareModal(userData: RankInUserType, targetPointRanking: PointRankingType) {
      const { getDisplayDateJa } = useDisplayDependingOnLang()
      const shareRankingImageInfo = {
        displayName: this.getDisplayUserName(this.retrieveNameUsersByUserId, userData),
        point: userData.point,
        ranking: {
          key: targetPointRanking.key,
          type: targetPointRanking.rankingType,
          rank: userData.rank,
          title: targetPointRanking.postImageSNS.title,
          headline: targetPointRanking.postImageSNS.headline,
          mothDate: targetPointRanking.postImageSNS.imageMothDate,
        },
      }

      // 背景画像を設定する
      const backgroundImage = this.getTargetRankingUserImage(
        shareRankingImageInfo,
        this.rankingUserImageData,
        targetPointRanking.aggregationStartDate,
      )

      const backgroundImagePath = await this.getBackGroundImagePath(backgroundImage)

      const canvas = await this.createRankingImage(
        shareRankingImageInfo,
        backgroundImagePath,
        getDisplayDateJa(dayjs().valueOf(), 'YYYY / MM / DD'),
      )
      const fileEntry = await this.createTemporaryFileForCordova(
        `SFGO_${shareRankingImageInfo.ranking.type}_RANKING_${shareRankingImageInfo.ranking.rank}.png`,
        FileConverter.base64ToBlob(canvas.canvas.toDataURL('image/png'), 'image/png'),
      )
      // eslint-disable-next-line
      // @ts-ignore
      window.plugins.socialsharing.share(
        `${I18n.t('MissionPage.RankingPage.Share.osStandardModal.message', {
          hashTag: process.env.VUE_APP_X_HASH_TAG,
          rankingTitle: targetPointRanking.title,
          ranking: userData.rank,
        })}`,
        null,
        fileEntry.nativeURL,
        null,
        () => {
          Logger.info('MissionPage#openOsShareModal: Success shared image.')
          this.removeFile(fileEntry)
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          Logger.error(
            `MissionPage#openOsShareModal: Failed shared image. error: ${JSON.stringify(error)}`,
          )
          this.removeFile(fileEntry)
        },
      )
    },
    /**
     * SNS投稿モーダルを表示する
     */
    async openSnsPostModal(today: string) {
      const canvas = await this.createRankingImage(
        this.shareRankingImageInfo,
        this.backgroundImagePath,
        today,
      )
      this.shareImageUrl = canvas.canvas.toDataURL('image/png')
      this.visiblePostSnsModal = true
    },
    /**
     * SNS投稿画像をダウンロードする
     */
    async downloadSnsShareImage(today: string) {
      const canvas = await this.createRankingImage(
        this.shareRankingImageInfo,
        this.backgroundImagePath,
        today,
      )

      if (DeviceInfo.isCordova()) {
        const downloadResult = await this.downloadFileForCordova(
          canvas.canvas.toDataURL('image/png').replace('data:image/png;base64,', ''),
        )
        if (!downloadResult.isSuccess) {
          if (downloadResult.response.errorCode === 'vp_110_008') {
            MessageDialogStore.value.open({
              title: I18n.tc('MissionPage.RankingPage.errors.downloadImagePermissionError.title'),
              message: I18n.tc(
                'MissionPage.RankingPage.errors.downloadImagePermissionError.message',
              ),
            })
          } else {
            MessageDialogStore.value.open({
              title: I18n.tc('MissionPage.RankingPage.errors.downloadImageError.title'),
              message: I18n.tc('MissionPage.RankingPage.errors.downloadImageError.message'),
            })
          }
        } else {
          MessageDialogStore.value.open({
            title: I18n.tc('MissionPage.RankingPage.success.downloadImage.title'),
            message: I18n.tc('MissionPage.RankingPage.success.downloadImage.message'),
          })
        }
      } else {
        this.downloadFile(
          canvas.canvas.toDataURL('image/png'),
          `SFGO_${this.shareRankingImageInfo.ranking.type}_RANKING_${this.shareRankingImageInfo.ranking.rank}.png`,
        )
      }
    },
    /**
     * SNS投稿モーダルを閉じる
     */
    onClickCancel() {
      this.visiblePostSnsModal = false
    },
  },
})
