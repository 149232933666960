






















import {
  computed,
  defineComponent,
  onBeforeUnmount,
  toRefs,
  watch,
  inject,
} from '@vue/composition-api'
import VueRouter from 'vue-router'
import dayjs from 'dayjs'
import useVideoPlayer from '@/components/hook/useVideoPlayer'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import Logger from '@/util/logger/Logger'
import useResumeVideoPlayback from '@/components/RaceVideoPage/hook/useResumeVideoPlayback'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * レース動画再生画面 動画プレーヤーセクションのコンポーネント
 */
export default defineComponent({
  name: 'VideoPlayerSection',
  props: {
    /**
     * コンテンツモード
     */
    viewMode: {
      type: String,
      required: true,
    },
    /**
     * 動画ライブ配信フラグ
     */
    live: {
      type: Boolean,
      default: false,
    },
    /**
     * 画面向き
     */
    screenOrientationType: {
      type: String,
      default: 'portrait-primary',
    },
    /**
     * ビデオフルスケールフラグ
     */
    videoFullScale: {
      type: Boolean,
      default: false,
    },
    /**
     * 動画再生時に初期設定する再生位置の実時間。UnixTime(ミリ秒)
     */
    movieStartActualTime: {
      type: Number,
      required: false,
    },
    /**
     * サーキットモードトグルボタンで再生モードを切り替えたかどうか
     */
    togglePlayMode: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const appConfigStore = StoreUtil.useStore('AppConfigStore')
    const raceVideoPageStore = StoreUtil.useStore('RaceVideoPageStore')

    const router = inject('router') as VueRouter

    const {
      contentsInfo,
      officialMovieInfo,
      currentPlayerOnBoardMovieInfo,
      currentMovieInfo,
      setViewAngle,
      computeCurrentTime,
      currentAngleId,
      getMovieInfo,
      isLive,
      highlightStore,
      computeMovieStartTime,
    } = raceVideoPageStore

    const { highlights } = highlightStore
    const { highlightSelectionStatus, skipChangeVideoPlayer } = toRefs(
      highlightStore.raceVideoPageHighlightState,
    )

    // 動画をレジューム再生させるための設定を行う
    const resumeVideoPlayback = useResumeVideoPlayback()

    const resumePlaybackPosition = resumeVideoPlayback.getRecordedPlaybackPosition()
    // 記録されている再生位置
    const resumeCurrentTime =
      resumePlaybackPosition && resumePlaybackPosition.movieId === currentMovieInfo.value.angleId
        ? resumePlaybackPosition.currentTime
        : 0
    // 動画プレーヤー内で設定されるonBeforeUnmountよりも早く呼び出されたいため、ここで設定する。
    onBeforeUnmount(() => {
      resumeVideoPlayback.recordVideoPlaybackPosition()
    })

    // 動画プレーヤーを初期化
    const loginStore = StoreUtil.useStore('LoginStore')
    if (!currentPlayerOnBoardMovieInfo.value?.playlistPath) {
      setViewAngle('race')
    }

    // URLにハイライトIDが含まれている場合、最新位置にシークさせない
    const { highlightId: _highlightId } = router.currentRoute.params

    const { videoPlayer, videoStatus, videoPlayerStatus } = useVideoPlayer({
      id: 'sfgo-video',
      target: 0,
      movieUrl:
        appConfigStore.currentCircuitMode.value && officialMovieInfo.value.audioPlaylistUrl
          ? officialMovieInfo.value.audioPlaylistUrl
          : currentMovieInfo.value.playlistUrl,
      live: isLive.value,
      playerType: appConfigStore.useVideoPlayerType(!officialMovieInfo.value.audioPlaylistUrl),
      refreshToken: loginStore.refreshToken,
      cookies: loginStore.movieCookies,
      currentTime:
        computeMovieStartTime(currentMovieInfo.value, props.movieStartActualTime) ||
        resumeCurrentTime,
      readVideoTrackTime: currentMovieInfo.value.videoTrackTimeEnabled,
      recordingStartTime: currentMovieInfo.value.recordingStartTimeCalculated,
      timeDeviations: appConfigStore.getTimeDeviation(),
      movieLength: currentMovieInfo.value.movieLength || undefined,
      seekToEnd: isLive.value && props.togglePlayMode !== 'true' && !_highlightId,
      zPositionForNative: -1,
      isBackgroundPlayEnable: DeviceInfo.isCordova() && appConfigStore.currentCircuitMode.value,
      startHidden: DeviceInfo.isCordova() && appConfigStore.currentCircuitMode.value,
      correctVideoTrackDateTimeToCurrent: isLive.value && appConfigStore.currentCircuitMode.value,
    })

    resumeVideoPlayback.clearRecordedPlaybackPosition()

    const checkComputedCurrentTime = (currentTime: number, isSeekToLatestPosition = false) => {
      if (currentTime < 0) {
        return MessageDialogStore.value.open({
          title: isSeekToLatestPosition
            ? I18n.tc('RaceVideoPage.errors.OpponentVideoNotFoundSeekToLatest.title')
            : I18n.tc('RaceVideoPage.errors.OpponentVideoNotFound.title'),
          message: isSeekToLatestPosition
            ? I18n.tc('RaceVideoPage.errors.OpponentVideoNotFoundSeekToLatest.message')
            : I18n.tc('RaceVideoPage.errors.OpponentVideoNotFound.message'),
        })
      }
      return Promise.resolve()
    }

    /**
     * 動画情報の変更を監視し、変更があった場合に動画プレーヤーに設定する。
     */
    watch(currentMovieInfo, async (movieInfo, oldMovieInfo) => {
      try {
        if (!getMovieInfo.value) {
          return
        }
        if (skipChangeVideoPlayer.value) {
          // フラグがtrueの場合、動画変更をスキップする
          return
        }

        // 再生中だったかどうか
        let played = videoPlayerStatus.playStatus === 'PLAY'

        let seekTime
        if (movieInfo.highlightPlayInfo && highlightSelectionStatus.value) {
          // ハイライトが選択されている場合、アングル切り替えと同時にシークを行うため、移動先の時間を習得する
          const { highlightId } = movieInfo.highlightPlayInfo
          const highlight = highlights.value.find(
            (highlightData) => highlightData.id === highlightId,
          )
          // ハイライト選択時は再生状態にする
          played = true
          if (highlightSelectionStatus.value === 'selectAndSeeking') {
            // 対象のハイライトの再生位置にシークする
            seekTime = highlight?.startTime
          }
        }

        if (seekTime) {
          try {
            if (movieInfo.angleId === 'main') {
              // 移動先が日本語公式映像（メインアングル）の場合、映像切り替えと、ハイライトのstartTimeの位置にシークする
              // ※ ハイライトのstartTimeは、公式映像の再生位置が設定されるため、映像切り替えとシークのみでよい
              await videoPlayer.setMovieUrl(
                currentMovieInfo.value.playlistUrl,
                seekTime,
                loginStore.movieCookies,
                currentMovieInfo.value.videoTrackTimeEnabled,
              )
            } else if (oldMovieInfo.angleId === 'main') {
              // 日本語公式映像（メインアングル）からオンボード映像に切り替える場合、seekTimeの再生位置が、オンボード映像での再生位置を計算してから映像切り替えを行う
              // ※ ハイライトのstartTimeは、公式映像の再生位置が設定されるため、オンボード映像での再生位置を計算する必要がある
              const nextCurrentTime = computeCurrentTime(
                currentAngleId.value,
                oldMovieInfo,
                seekTime,
              )
              await checkComputedCurrentTime(nextCurrentTime)
              await videoPlayer.setMovieUrl(
                currentMovieInfo.value.playlistUrl,
                nextCurrentTime < 0 ? 0 : nextCurrentTime,
                loginStore.movieCookies,
                currentMovieInfo.value.videoTrackTimeEnabled,
              )
            } else if (movieInfo.angleId && oldMovieInfo?.angleId) {
              // オンボード映像または英語公式映像 からオンボード映像または英語公式映像への切り替えの場合、ハイライトの再生位置を現在再生しているオンボード映像の再生位置に変更してから、
              // 変更後のオンボード映像の再生位置を求めてシークする
              const nextCurrentTime = computeCurrentTime(
                oldMovieInfo.angleId,
                contentsInfo.value,
                seekTime,
              )
              await checkComputedCurrentTime(nextCurrentTime)
              const nextCurrentTime2 = computeCurrentTime(
                movieInfo.angleId,
                oldMovieInfo,
                nextCurrentTime < 0 ? 0 : nextCurrentTime,
              )
              await checkComputedCurrentTime(nextCurrentTime2)
              await videoPlayer.setMovieUrl(
                currentMovieInfo.value.playlistUrl,
                nextCurrentTime2 < 0 ? 0 : nextCurrentTime2,
                loginStore.movieCookies,
                currentMovieInfo.value.videoTrackTimeEnabled,
              )
            }
          } catch (e) {
            // 動画変更できなかった場合、公式映像に切り替える
            await MessageDialogStore.value.open({
              title: I18n.t('RaceVideoPage.errors.VideoNotFound.title') as string,
              message: I18n.t('RaceVideoPage.errors.VideoNotFound.message') as string,
            })
            try {
              await videoPlayer.setMovieUrl(
                currentMovieInfo.value.playlistUrl,
                seekTime,
                loginStore.movieCookies,
                currentMovieInfo.value.videoTrackTimeEnabled,
              )
            } catch (e2) {
              Logger.error(`RaceVideoPage#watch(currentMovieInfo): Failed to switch movie.`, e2)
            }

            setViewAngle('race')
          }
        } else {
          try {
            // 同一アングルに切り替える操作はブロックする
            // 経緯：アングル切り替え時に同じ同じアングルに対して複数回本処理が呼び出され、不要な動画切り替えが発火している場合があった。その動作を制限するため。
            //  → 2024/07/23 に一時的に再現していたが、現在は再現できない。
            if (currentAngleId.value === oldMovieInfo.angleId) {
              return
            }

            // 公式映像とオンボード映像切り替え、または、オンボード映像からオンボード映像への切り替え
            const nextCurrentTime = computeCurrentTime(currentAngleId.value, oldMovieInfo)
            // 映像の最後にシークするかどうか
            const isSeekToLatestPosition = isLive.value && nextCurrentTime < 0
            await checkComputedCurrentTime(nextCurrentTime, isSeekToLatestPosition)
            await videoPlayer.setMovieUrl(
              currentMovieInfo.value.playlistUrl,
              nextCurrentTime < 0 ? 0 : nextCurrentTime,
              loginStore.movieCookies,
              currentMovieInfo.value.videoTrackTimeEnabled,
            )

            if (isSeekToLatestPosition) {
              /**
               * ライブ配信中 かつ 切り替え後の映像に現在の再生位置に対応する再生位置がなかった場合は映像の最後にシークする
               * ※ ライブ配信動画が指定された時のふるまいをネイティブプレーヤーと一致させるため、この処理を行う
               */
              await videoPlayer?.seekEndOfVideo()
            }
          } catch (e) {
            // 切り替えエラーとなった場合、公式映像に切り替える
            await MessageDialogStore.value.open({
              title: I18n.t('RaceVideoPage.errors.VideoChangeError.title') as string,
              message: I18n.t('RaceVideoPage.errors.VideoChangeError.message') as string,
            })
            const nextCurrentTime = officialMovieInfo.value.angleId
              ? computeCurrentTime(officialMovieInfo.value.angleId, oldMovieInfo)
              : 0
            try {
              await videoPlayer.setMovieUrl(
                officialMovieInfo.value.playlistUrl,
                nextCurrentTime < 0 ? 0 : nextCurrentTime,
                loginStore.movieCookies,
                currentMovieInfo.value.videoTrackTimeEnabled,
              )

              if (isLive.value && nextCurrentTime < 0) {
                /**
                 * ライブ配信中 かつ 切り替え後の映像に現在の再生位置に対応する再生位置がなかった場合は映像の最後にシークする
                 * ※ ライブ配信動画が指定された時のふるまいをネイティブプレーヤーと一致させるため、この処理を行う
                 */
                await videoPlayer?.seekEndOfVideo()
              }
            } catch (e2) {
              Logger.error(`RaceVideoPage#watch(currentMovieInfo): Failed to switch movie.`, e2)
            }

            setViewAngle('race')
          }
        }

        if (played) {
          // アングル変更をすると再生が止まるため、再生中だった場合は再生を再開する
          await videoPlayer.play()
        }
      } finally {
        highlightSelectionStatus.value = null
      }
    })

    raceVideoPageStore.setVideoPlayer(videoPlayer)
    raceVideoPageStore.setVideoStatus(videoStatus)
    raceVideoPageStore.setVideoPlayerStatus(videoPlayerStatus)

    const videoFullScaleStatus = computed(() => props.videoFullScale)

    /**
     * 現在再生している動画が記録された実時間を算出する。
     * デバッグ用に映像のタイムコードを画面に表示して確認するために利用される。
     */
    const currentVideoTrackTime = computed(() => {
      if (videoStatus.currentVideoTrackDateTime) {
        return dayjs(videoStatus.currentVideoTrackDateTime)
          .local()
          .format('YYYY-MM-DD HH:mm:ss;SSS')
      }
      return '--:--:--'
    })

    const showTimeCode = process.env.VUE_APP_SHOW_TIME_CODE === 'true'
    return {
      videoPlayerStatus,
      videoFullScaleStatus,
      currentVideoTrackTime,
      showTimeCode,
    }
  },
})
