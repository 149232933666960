import { Location, RouteConfig } from 'vue-router'
import { makeRouter } from '@/router/'
import PageWrapper from '@/components/pc/common/PageWrapper.vue'
import LoginPage from '@/views/pc/LoginPage.vue'
import HomePage from '@/views/pc/HomePage/HomePage.vue'
import RegistrationFormPage from '@/views/SignupPage/RegistrationFormPage.vue'
import EmailAuthenticationPage from '@/views/SignupPage/EmailAuthenticationPage.vue'
import ConfirmationSentPage from '@/views/SignupPage/ConfirmationSentPage.vue'
import RegistrationPage from '@/views/SignupPage/RegistrationPage.vue'
import PlanSelectPage from '@/views/SignupPage/PlanSelectPage.vue'
import SignupCompletePage from '@/views/SignupPage/SignupCompletePage.vue'
import ErrorPage from '@/views/ErrorPage/ErrorPage.vue'
import SignupPaymentConfirmPage from '@/views/SignupPage/SignupPaymentConfirmPage.vue'
import AccountSettingsWrapper from '@/views/pc/MyPage/AccountSettingsWrapper.vue'
import MyPageWrapper from '@/views/pc/MyPage/MyPageWrapper.vue'
import PaymentEditPage from '@/views/pc/MyPage/PaymentPage/PaymentEditPage.vue'
import PaymentConfirmPage from '@/views/pc/MyPage/PaymentPage/PaymentConfirmPage.vue'
import PaymentCompletePage from '@/views/pc/MyPage/PaymentPage/PaymentCompletePage.vue'
import PaidMembershipChangeEditPage from '@/views/pc/MyPage/PaidMembershipChangePage/PaidMembershipChangeEditPage.vue'
import PaidMembershipChangeConfirmPage from '@/views/pc/MyPage/PaidMembershipChangePage/PaidMembershipChangeConfirmPage.vue'
import PaidMembershipChangeCompletePage from '@/views/pc/MyPage/PaidMembershipChangePage/PaidMembershipChangeCompletePage.vue'
import PaidPlanChangeEditPage from '@/views/pc/MyPage/PaidPlanChangePage/PaidPlanChangeEditPage.vue'
import PaidPlanChangeConfirmPage from '@/views/pc/MyPage/PaidPlanChangePage/PaidPlanChangeConfirmPage.vue'
import PaidPlanChangeCompletePage from '@/views/pc/MyPage/PaidPlanChangePage/PaidPlanChangeCompletePage.vue'
import CancelPaidPlanEditPage from '@/views/pc/MyPage/CancelPaidPlanPage/CancelPaidPlanEditPage.vue'
import CancelPaidPlanConfirmPage from '@/views/pc/MyPage/CancelPaidPlanPage/CancelPaidPlanConfirmPage.vue'
import CancelPaidPlanCompletePage from '@/views/pc/MyPage/CancelPaidPlanPage/CancelPaidPlanCompletePage.vue'
import CancelConfirmPage from '@/views/pc/MyPage/CancelPage/CancelConfirmPage.vue'
import CancelCompletePage from '@/views/pc/MyPage/CancelPage/CancelCompletePage.vue'
import MainPage from '@/views/MainPage/MainPage.vue'
import RaceListPage from '@/views/pc/RaceListPage/RaceListPage.vue'
import RaceVideoPage from '@/views/pc/RaceVideoPage/RaceVideoPage.vue'
import LinkPage from '@/views/pc/LinkPage/LinkPage.vue'
import AwardPage from '@/views/pc/LinkPage/AwardPage.vue'
import MypageTopPage from '@/views/MypagePage/MypageTopPage.vue'
import MypageUserEditPage from '@/views/MypagePage/MypageUserEditPage.vue'
import MypageUserConfirmPage from '@/views/MypagePage/MypageUserConfirmPage.vue'
import MypageUserCompletePage from '@/views/MypagePage/MypageUserCompletePage.vue'
import MypageMailChangeCompletePage from '@/views/MypagePage/MypageMailChangeCompletePage.vue'
import MypageFavoriteTeamSelectPage from '@/views/MypagePage/MypageFavoriteTeamSelectPage.vue'
import MypageFavoritePlayerSelectPage from '@/views/MypagePage/MypageFavoritePlayerSelectPage.vue'
import MypageLanguageSettingPage from '@/views/MypagePage/MypageLanguageSettingPage.vue'
import MypageDetailsSettingPage from '@/views/MypagePage/MypageDetailsSettingPage.vue'
import MypageAboutUsingPage from '@/views/MypagePage/MypageAboutUsingPage.vue'
import MypageContactUsPage from '@/views/MypagePage/MypageContactUsPage.vue'
import MypageUserInitPage from '@/views/MypagePage/MypageUserInitPage.vue'
import MypageFavoritePlayerInitSelectPage from '@/views/MypagePage/MypageFavoritePlayerInitSelectPage.vue'
import MypageFavoriteTeamInitSelectPage from '@/views/MypagePage/MypageFavoriteTeamInitSelectPage.vue'
import MypageUserInitConfirmPage from '@/views/MypagePage/MypageUserInitConfirmPage.vue'
import MypageOneTimePassInputPage from '@/views/pc/MyPage/OneTimePassPage/MypageOneTimePassInputPage.vue'
import MypageOneTimePassConfirmPage from '@/views/pc/MyPage/OneTimePassPage/MypageOneTimePassConfirmPage.vue'
import MypageOneTimePassCompletePage from '@/views/MypagePage/MypageOneTimePassCompletePage.vue'
import MypageReferralCodePage from '@/views/pc/MyPage/ReferralCodePage/MypageReferralCodePage.vue'
import ContractPage from '@/views/MypagePage/MypageContractPage.vue'
import ContractLogPage from '@/views/MypagePage/MypageContractLogPage.vue'
import PasswordResetPage from '@/views/pc/PasswordResetPage/PasswordResetPage.vue'
import PasswordResetMailInputPage from '@/views/PasswordResetPage/PasswordResetMailInputPage.vue'
import PasswordResetMailSentPage from '@/views/PasswordResetPage/PasswordResetMailSentPage.vue'
import PasswordResetNewPasswordPage from '@/views/PasswordResetPage/PasswordResetNewPasswordPage.vue'
import PasswordResetCompletePage from '@/views/PasswordResetPage/PasswordResetCompletePage.vue'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import StoreUtil from '@/store/StoreUtil'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import HomeSnsPlayerSelectPage from '@/views/HomePage/HomeSnsPlayerSelectPage.vue'
import HomeSnsTeamSelectPage from '@/views/HomePage/HomeSnsTeamSelectPage.vue'
import StandingsPageWrapper from '@/views/pc/StandingsPage/StandingsPageWrapper.vue'
import StandingsTopPage from '@/views/StandingsPage/StandingsTopPage.vue'
import StandingsDetailsPage from '@/views/StandingsPage/StandingsDetailsPage.vue'
import NotificationPage from '@/views/pc/NotificationPage/NotificationPage.vue'
import MypageEcSiteConnectPage from '@/views/MypagePage/MypageEcSiteConnectPage.vue'
import MissionPage from '@/views/pc/MissionPage/MissionPage.vue'
import MotorsportCalendarPage from '@/views/pc/LinkPage/MotorsportCalendarPage.vue'
import MissionRankingPage from '@/views/pc/MissionPage/MissionRankingPage.vue'
import MissionTopPage from '@/views/pc/MissionPage/MissionTopPage.vue'
import MypageTicketPageWrapper from '@/views/MypagePage/MyPageTicketPage/MypageTicketPageWrapper.vue'
import MypageTicketListPage from '@/views/MypagePage/MyPageTicketPage/MypageTicketListPage.vue'
import MypageTicketPastPage from '@/views/MypagePage/MyPageTicketPage/MypageTicketPastPage.vue'
import RewardPage from '@/views/pc/RewardPage/RewardPage.vue'
import MypageBlockedUserListPage from '@/views/MypagePage/MypageBlockedUserListPage.vue'
import MypagePointExpirationDatePage from '@/views/MypagePage/MypagePointExpirationDatePage.vue'
import MypagePointHistoryPage from '@/views/MypagePage/MypagePointHistoryPage.vue'
import MypageTicketTodayPage from '@/views/MypagePage/MyPageTicketPage/MypageTicketTodayPage.vue'

/**
 * PC用のルーティング
 */
const pcRoutes: Array<RouteConfig> = [
  {
    path: '',
    component: PageWrapper, // PC用 Page Wrapper
    children: [
      {
        path: '/login',
        name: 'PCLoginPage',
        component: LoginPage,
        children: [
          {
            path: '/login/:loginType',
            name: 'EditLoginPage',
            component: LoginPage,
            /**
             * アプリから遷移したかどうかを判定する
             * 【対象のloginType】
             * paid-membership（無料から有料プランに切り替える）
             * cancel-paid-plan（有料会員を解約）
             * payment（クレジットカード情報の変更）
             * paid-plan-change（支払いプランを切り替える（有料→有料））
             * mypage-contract（ご契約内容）
             * mypage-contract-log（プラン契約履歴）
             */
            beforeEnter: (to, from, next) => {
              const targetLoginTypes = [
                'paid-membership',
                'cancel-paid-plan',
                'payment',
                'paid-plan-change',
                'mypage-contract',
                'mypage-contract-log',
              ]
              if (targetLoginTypes.find((v) => v === to.params.loginType)) {
                LocalStorageAccessor.transitionFromMobileApp = to.query.returnApp === 'true'
              }
              next()
            },
          },
        ],
      },
      {
        path: '/logout',
        beforeEnter: () => {
          // ログアウトする
          LocalStorageAccessor.transitionFromLogout = true
          // Storeの状態をクリアするためログアウト時にリロードする
          window.location.reload()
        },
      },
      {
        path: '/',
        component: MainPage,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/',
            name: 'TopPage',
            component: RaceListPage,
            props: (route) => ({
              isDataFetched: route.params.isDataFetched === 'true',
            }),
          },
          {
            path: '/home',
            name: 'HomePage',
            component: HomePage,
          },
          {
            path: '/home/player-select',
            name: 'HomeSnsPlayerSelectPage',
            component: HomeSnsPlayerSelectPage,
          },
          {
            path: '/home/team-select',
            name: 'HomeSnsTeamSelectPage',
            component: HomeSnsTeamSelectPage,
          },
          {
            path: '/race-video/:championshipMasterId/:raceId/:highlightId?',
            name: 'RaceVideoPage',
            component: RaceVideoPage,
            props: (route) => ({
              // 大会ID
              championshipMasterId: route.params.championshipMasterId,
              // レースID
              raceId: route.params.raceId,
              // 最初に再生するハイライト情報(オプション)
              highlightId: route.params.highlightId,
              isDataFetched: route.params.isDataFetched === 'true',
            }),
          },
          {
            path: '/race-video/:championshipMasterId/:raceId/:movieStartActualTime/:driverId',
            name: 'RaceVideoPage',
            component: RaceVideoPage,
            props: (route) => ({
              // 大会ID
              championshipMasterId: route.params.championshipMasterId,
              // レースID
              raceId: route.params.raceId,
              // 最初に再生する動画情報の実時刻
              movieStartActualTime: Number(route.params.movieStartActualTime),
              driverId: route.params.driverId,
            }),
          },
          {
            path: '/link',
            name: 'LinkPage',
            component: LinkPage,
          },
          {
            path: '/notification',
            name: 'NotificationPage',
            component: NotificationPage,
          },
        ],
      },
      {
        path: '/link/award',
        name: 'AwardPage',
        component: AwardPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/link/calendar',
        name: 'MotorsportCalendar',
        component: MotorsportCalendarPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/mission',
        name: 'Mission',
        component: MissionTopPage,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'MissionPage',
            component: MissionPage,
          },
          {
            path: '/mission/ranking',
            name: 'MissionRankingPage',
            component: MissionRankingPage,
          },
        ],
      },
      {
        path: '/standings',
        meta: {
          requiresAuth: true,
        },
        component: StandingsPageWrapper,
        children: [
          {
            path: '',
            name: 'StandingsTopPage',
            component: StandingsTopPage,
          },
          {
            path: '/standings/details/:playerMasterId',
            name: 'StandingsDetailsPage',
            component: StandingsDetailsPage,
            props: (route) => ({
              playerMasterId: route.params.playerMasterId,
            }),
          },
        ],
      },
      {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage,
      },
      {
        path: '/signup',
        component: RegistrationFormPage,
        children: [
          {
            path: '/signup/confirmation-sent',
            name: 'ConfirmationSentPage',
            component: ConfirmationSentPage,
          },
          {
            path: '/signup/registration',
            name: 'RegistrationPage',
            component: RegistrationPage,
            props: (route) => ({
              tokenId: route.query.tokenId,
            }),
          },
          {
            path: '/signup/plan-select',
            name: 'PlanSelectPage',
            component: PlanSelectPage,
            props: (route) => ({
              continueMemberType: route.query.continueMemberType,
            }),
            beforeEnter: (to, from, next) => {
              const errorStore = StoreUtil.useStore('ErrorStore')
              if (!LoginStore.value.isLogin) {
                // ログアウト状態だとプランの表示、登録ができないため、エラーを表示する
                errorStore.setConfig('breakCreateContract')
                const route = { path: '/error' } as Location
                next(route)
                return
              }
              next()
            },
          },
          {
            path: '/signup/payment-confirm',
            name: 'SignupPaymentConfirmPage',
            component: SignupPaymentConfirmPage,
            beforeEnter: (to, from, next) => {
              const errorStore = StoreUtil.useStore('ErrorStore')
              if (!LoginStore.value.isLogin) {
                // ログアウト状態だとプランの表示、登録ができないため、エラーを表示する
                errorStore.setConfig('breakCreateContract')
                const route = { path: '/error' } as Location
                next(route)
                return
              }
              next()
            },
          },
          {
            path: '/signup/complete',
            name: 'SignupCompletePage',
            component: SignupCompletePage,
          },
          {
            path: '/signup/:memberType',
            name: 'EmailAuthenticationPage',
            component: EmailAuthenticationPage,
            props: (route) => ({
              // 会員登録タイプ
              memberType: <MemberType>route.params.memberType,
            }),
          },
        ],
      },
      {
        path: '/payment',
        component: AccountSettingsWrapper,
        beforeEnter: (to, from, next) => {
          const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
          const { isFreePlan, hasNoPlan } = contractInfoStore
          if (isFreePlan || hasNoPlan) {
            // 無料会員またはプランなしの場合はトップページに遷移させる
            const route = { path: '/' } as Location
            next(route)
            return
          }
          next()
        },
        children: [
          {
            path: '/payment/edit',
            name: 'PaymentEditPage',
            component: PaymentEditPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/payment/confirm',
            name: 'PaymentConfirmPage',
            component: PaymentConfirmPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/payment/complete',
            name: 'PaymentCompletePage',
            component: PaymentCompletePage,
          },
        ],
      },
      {
        path: '/paid-membership',
        component: AccountSettingsWrapper,
        beforeEnter: (to, from, next) => {
          const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
          const { isPaidPlan, isCouponPlan, isTrialPlan, hasNoPlan } = contractInfoStore
          if (!hasNoPlan && (isPaidPlan || isCouponPlan || isTrialPlan)) {
            // プランがあり かつ 有料会員（クーポン適用中の有料会員も含む）の場合はトップページに遷移させる
            const route = { path: '/' } as Location
            next(route)
            return
          }
          // 有料会員変更ページに遷移する
          next()
        },
        children: [
          {
            path: '/paid-membership/edit',
            name: 'PaidMembershipChangeEditPage',
            component: PaidMembershipChangeEditPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/paid-membership/confirm',
            name: 'PaidMembershipChangeConfirmPage',
            component: PaidMembershipChangeConfirmPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/paid-membership/complete',
            name: 'PaidMembershipChangeCompletePage',
            component: PaidMembershipChangeCompletePage,
          },
        ],
      },
      {
        path: '/paid-plan-change',
        component: AccountSettingsWrapper,
        beforeEnter: (to, from, next) => {
          const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
          const { isFreePlan, hasNoPlan } = contractInfoStore
          if (isFreePlan || hasNoPlan) {
            // 無料会員またはプランなしの場合はトップページに遷移させる
            const route = { path: '/' } as Location
            next(route)
            return
          }
          next()
        },
        children: [
          {
            path: '/paid-plan-change/edit',
            name: 'PaidPlanChangeEditPage',
            component: PaidPlanChangeEditPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/paid-plan-change/confirm',
            name: 'PaidPlanChangeConfirmPage',
            component: PaidPlanChangeConfirmPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/paid-plan-change/complete',
            name: 'PaidPlanChangeCompletePage',
            component: PaidPlanChangeCompletePage,
          },
        ],
      },
      {
        path: '/cancel-paid-plan',
        component: AccountSettingsWrapper,
        beforeEnter: (to, from, next) => {
          const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
          const { isFreePlan, hasNoPlan } = contractInfoStore
          if (isFreePlan || hasNoPlan) {
            // 無料会員またはプランなしの場合はトップページに遷移させる
            const route = { path: '/' } as Location
            next(route)
            return
          }
          next()
        },
        children: [
          {
            path: '/cancel-paid-plan/edit',
            name: 'CancelPaidPlanEditPage',
            component: CancelPaidPlanEditPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/cancel-paid-plan/confirm',
            name: 'CancelPaidPlanConfirmPage',
            component: CancelPaidPlanConfirmPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/cancel-paid-plan/complete',
            name: 'CancelPaidPlanCompletePage',
            component: CancelPaidPlanCompletePage,
          },
        ],
      },
      {
        path: '/cancel',
        component: AccountSettingsWrapper,
        beforeEnter: (to, from, next) => {
          const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
          const { isPaidPlan, isCouponPlan, isTrialPlan } = contractInfoStore
          // 有料会員（クーポン適用中の有料会員も含む）、トライアル会員の場合はトップページに遷移させる
          if (isPaidPlan || isCouponPlan || isTrialPlan) {
            const route = { path: '/' } as Location
            next(route)
            return
          }
          next()
        },
        children: [
          {
            path: '/cancel/confirm',
            name: 'CancelConfirmPage',
            component: CancelConfirmPage,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/cancel/complete',
            name: 'CancelCompletePage',
            component: CancelCompletePage,
          },
        ],
      },
      {
        path: '/mypage',
        component: MyPageWrapper,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'MypageTopPage',
            component: MypageTopPage,
          },
          {
            path: '',
            component: MypageTicketPageWrapper,
            children: [
              {
                path: '/mypage/ticket-list',
                name: 'MypageTicketListPage',
                component: MypageTicketListPage,
              },
              {
                path: '/mypage/ticket-today',
                name: 'MypageTicketTodayPage',
                component: MypageTicketTodayPage,
              },
              {
                path: '/mypage/ticket-past',
                name: 'MypageTicketPastPage',
                component: MypageTicketPastPage,
              },
            ],
          },
          {
            path: '/mypage/point-history',
            name: 'MypagePointHistoryPage',
            component: MypagePointHistoryPage,
          },
          {
            path: '/mypage/point-expiration-date',
            name: 'MypagePointExpirationDatePage',
            component: MypagePointExpirationDatePage,
          },
          {
            path: '/mypage/user-edit',
            name: 'MypageUserEditPage',
            component: MypageUserEditPage,
          },
          {
            path: '/mypage/user-confirm',
            name: 'MypageUserConfirmPage',
            component: MypageUserConfirmPage,
          },
          {
            path: '/mypage/user-complete',
            name: 'MypageUserCompletePage',
            component: MypageUserCompletePage,
          },
          {
            path: '/mypage/favorite-team',
            name: 'MypageFavoriteTeamSelectPage',
            component: MypageFavoriteTeamSelectPage,
          },
          {
            path: '/mypage/favorite-player',
            name: 'MypageFavoritePlayerSelectPage',
            component: MypageFavoritePlayerSelectPage,
          },
          {
            path: '/mypage/language',
            name: 'MypageLanguageSettingPage',
            component: MypageLanguageSettingPage,
          },
          {
            path: '/mypage/ec-site-connect',
            name: 'MypageEcSiteConnect',
            component: MypageEcSiteConnectPage,
          },
          {
            path: '/mypage/details-setting',
            name: 'MypageDetailsSettingPage',
            component: MypageDetailsSettingPage,
          },
          {
            path: '/mypage/blocked-user-list',
            name: 'MypageBlockedUserListPage',
            component: MypageBlockedUserListPage,
          },
          {
            path: '/mypage/about-using',
            name: 'MypageAboutUsingPage',
            component: MypageAboutUsingPage,
          },
          {
            path: '/mypage/contact-us',
            name: 'MypageContactUsPage',
            component: MypageContactUsPage,
          },
          {
            path: '/mypage/one-time-pass-input',
            name: 'MypageOneTimePassInputPage',
            component: MypageOneTimePassInputPage,
            beforeEnter: (to, from, next) => {
              const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
              const { isPaidPlan, isCouponPlan, isTrialPlan } = contractInfoStore
              if (isPaidPlan || isCouponPlan || isTrialPlan) {
                // 有料会員（クーポン適用中の有料会員も含む）の場合はトップページに遷移させる
                const route = { path: '/' } as Location
                next(route)
                return
              }
              // ワンタイムパスの登録ページに遷移する
              next()
            },
          },
          {
            path: '/mypage/one-time-pass-confirm',
            name: 'MypageOneTimePassConfirmPage',
            component: MypageOneTimePassConfirmPage,
          },
          {
            path: '/mypage/one-time-pass-complete',
            name: 'MypageOneTimePassCompletePage',
            component: MypageOneTimePassCompletePage,
          },
          {
            path: '/mypage/referral-code',
            name: 'MypageReferralCodePage',
            component: MypageReferralCodePage,
          },
          {
            path: '/mypage/contract',
            name: 'ContractPage',
            component: ContractPage,
          },
          {
            path: '/mypage/contract-log',
            name: 'ContractLogPage',
            component: ContractLogPage,
          },
        ],
      },
      {
        path: '/mypage',
        component: AccountSettingsWrapper,
        children: [
          {
            path: '/mypage/mail-change',
            name: 'MypageMailChangeCompletePage',
            component: MypageMailChangeCompletePage,
          },
        ],
      },
      {
        path: '/mypage',
        component: AccountSettingsWrapper,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/mypage/init',
            name: 'MypageUserInitPage',
            component: MypageUserInitPage,
          },
          {
            path: '/mypage/init/favorite-player',
            name: 'MypageFavoritePlayerInitSelectPage',
            component: MypageFavoritePlayerInitSelectPage,
          },
          {
            path: '/mypage/init/favorite-team',
            name: 'MypageFavoriteTeamInitSelectPage',
            component: MypageFavoriteTeamInitSelectPage,
          },
          {
            path: '/mypage/init-confirm',
            name: 'MypageUserInitConfirmPage',
            component: MypageUserInitConfirmPage,
          },
        ],
      },
      {
        path: '/password-reset',
        component: PasswordResetPage,
        children: [
          {
            path: '/password-reset/mail-sent',
            name: 'PasswordResetMailSentPage',
            component: PasswordResetMailSentPage,
          },
          {
            path: '/password-reset/new-password',
            name: 'PasswordResetNewPasswordPage',
            component: PasswordResetNewPasswordPage,
          },
          {
            path: '/password-reset/complete',
            name: 'PasswordResetCompletePage',
            component: PasswordResetCompletePage,
          },
          /**
           * NOTE: 以下をchildren配下の一番上に定義すると、/password-reset/mail-sentなどにアクセスした場合でも、ルーティングが/password-resetにマッチしてしまう。
           * そのため、以下をchildren配下の一番下に定義している。
           */
          {
            path: '/password-reset/:returnApp?',
            name: 'PasswordResetMailInputPage',
            component: PasswordResetMailInputPage,
          },
        ],
      },
      {
        path: '/reward',
        name: 'RewardPage',
        component: RewardPage,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]

const router = makeRouter(pcRoutes)
export default router
