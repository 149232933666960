















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypagePointHistoryPane from '@/components/MypagePointHistoryPage/MypagePointHistoryPane.vue'

export default defineComponent({
  name: 'MypagePointHistoryPage',
  components: { MypagePointHistoryPane, GlobalNavigationPane, SubHeaderSection },
  setup() {
    // TODO: ポイント有効期限表示対応時に消す
    const isIncludeNextRelease = process.env.VUE_APP_INCLUDE_NEXT_RELEASE === 'true'
    return {
      isIncludeNextRelease,
    }
  },
})
