
































import { computed, defineComponent, ref } from '@vue/composition-api'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import AtomLink from '@/components/atoms/AtomLink.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import i18n from '@/locales/I18n'
import Const from '@/util/Const'
import ModalCouponCodeInputParts from '@/components/common/modal/parts/ModalCouponCodeInputParts.vue'

export default defineComponent({
  name: 'LocationErrorSection',
  components: { ModalCouponCodeInputParts, ModalMessageParts, AtomLink, ModalTitleParts },
  setup() {
    // TODO: ダミーデータ：位置情報
    const currentLocationInfo = ref({
      lat: 35.5801675099006,
      lng: 139.6596416672605,
    })
    // TODO: ダミーデータ：店舗名
    const storeName = 'XXXXXX XXXXX店 XXXXX支店'

    // 位置情報エラーのメッセージ
    const locationErrorMessage = computed(() =>
      i18n.tc('common.flowLineToPaidPlan.discount.location.error', 1, [storeName]),
    )

    // 現在地のGoogle Mapのリンク
    const currentLocationGoogleMapUrl = computed(() => {
      const googleMapUrl =
        i18n.locale === 'ja' ? Const.EXTERNAL_LINKS.GOOGLE_MP.JA : Const.EXTERNAL_LINKS.GOOGLE_MP.EN
      return `${googleMapUrl}&ll=${currentLocationInfo.value.lat},${currentLocationInfo.value.lng}`
    })

    // 現在地の緯度経度情報を返す
    const displayCurrentLocation = computed(() =>
      i18n
        .t('MissionPage.checkIn.errors.outOfAreaOrPeriod.currentLocation', {
          lat: currentLocationInfo.value.lat,
          lng: currentLocationInfo.value.lng,
        })
        .toString(),
    )
    return {
      locationErrorMessage,
      currentLocationGoogleMapUrl,
      displayCurrentLocation,
    }
  },
})
