

















import { defineComponent, ref } from '@vue/composition-api'
import MypageBlockedUserItemSection from '@/components/MypageBlockedUserListPage/MypageBlockedUserListPane/MypageBlockedUserItemSection.vue'
import PaginationSection from '@/components/common/Pagination/PaginationSection.vue'

export default defineComponent({
  name: 'MypageBlockedUserListSection',
  components: { PaginationSection, MypageBlockedUserItemSection },
  setup() {
    // ブロックユーザーのリストデータ
    const dummyListData = [
      {
        id: '1',
        type: 'paid-user',
        name: 'kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123kazu123',
      },
      {
        id: '2',
        type: 'ambassador',
        name: 'yumi5678',
      },
      {
        id: '3',
        type: 'jrp-staff',
        name: 'tomo_89',
      },
      {
        id: '4',
        type: 'driver',
        name: 'sora2k',
      },
      {
        id: '5',
        type: 'team-user',
        name: 'rika777',
      },
      {
        id: '6',
        type: 'guest',
        name: 'akira_35',
      },
      {
        id: '7',
        type: 'free-user',
        name: 'mio4ever',
      },
      {
        id: '8',
        type: 'special-supporter',
        name: 'shinobi9',
      },
      {
        id: '9',
        type: 'special-supporter',
        name: 'shinobi9',
      },
    ]

    const totalPage = ref(12)
    const currentPage = ref(1)

    return {
      dummyListData,
      totalPage,
      currentPage,
    }
  },
})
