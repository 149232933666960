<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <path
      d="M0.666687 3.66699L7.33335 3.66699V4.33366L0.666687 4.33366L0.666687 3.66699Z"
      fill="#2B2B2B"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MypagePointMinusIcon',
})
</script>
