






















import { defineComponent, PropType } from '@vue/composition-api'

export interface CouponData {
  title: string
  subtitle: string
  description: string
  period: string
  price: number
}

export default defineComponent({
  name: 'ModalCouponPlanCardParts',
  props: {
    couponData: {
      type: Object as PropType<CouponData>,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
