import { render, staticRenderFns } from "./AwardPane.vue?vue&type=template&id=4cae2610&scoped=true"
import script from "./AwardPane.vue?vue&type=script&lang=ts"
export * from "./AwardPane.vue?vue&type=script&lang=ts"
import style0 from "./AwardPane.vue?vue&type=style&index=0&id=4cae2610&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cae2610",
  null
  
)

export default component.exports