import qs from 'qs'
import Logger from '@/util/logger/Logger'
import OAuth1Client from '@/util/oauth/OAuth1Client'
import { HTTPMethodType, HTTPRequestDataType, HTTPSerializerType } from '@/util/http/HTTPClient'

/**
 * OAuth トークンのリクエストに対するレスポンスデータの型
 */
type OAuthRequestTokenResponse = {
  oauth_token: string
  oauth_token_secret: string
  oauth_callback_confirmed: string
}

/**
 * OAuth アクセストークンのリクエストに対するレスポンスデータの型
 */
export type OAuthRequestAccessTokenResponse = {
  oauth_token: string
  oauth_token_secret: string
  screen_name: string
  user_id: string
}

/**
 * OAuth 1.0s 3-Legged OAuth Flow の認可処理を行う。
 * https://ja.docs.monaca.io/sampleapp/samples/twitter_sso の処理を参考にして実装されている。
 *
 * 現状、X(旧Twiiter) のOAuth Flowを実現するために実装しているため、他のOAUthプロバイダの場合、改修が必要となる可能性がある
 * https://developer.twitter.com/en/docs/authentication/oauth-1-0a/obtaining-user-access-tokens
 * https://developer.twitter.com/en/docs/tutorials/authenticating-with-twitter-api-for-enterprise/oauth1-0a-and-user-access-tokens
 */
export default class OAuth3LeggedFlowBrowser {
  /**
   * OAuth トークンのリクエストを開始するURL
   */
  requestTokenUrl: string

  /**
   * OAuth 認可リクエストを開始するURL
   */
  requestAuthorizeUrl: string

  /**
   * OAuth アクセストークンのリクエストを開始するURL
   */
  requestAccessTokenUrl: string

  /**
   * OAuth認可URLに指定するリダイレクトURL
   */
  redirectUri: string

  /**
   * OAuth1.0aクライアント
   */
  oauth1Client: OAuth1Client

  /**
   * コンシューマキー
   */
  consumerKey: string

  /**
   * コンシューマシークレットキー
   */
  consumerSecretKey: string

  /**
   * OAuth 1.0a 認可フローを開始するために必要な情報を設定する。
   *
   * @param requestTokenUrl OAuth トークン取得のリクエスト先
   * @param requestAuthorizeUrl OAuth 認可開始のリクエスト先
   * @param requestAccessTokenUrl OAuth アクセストークンのリクエスト先
   * @param redirectUri OAuth 認可結果を返す際のリダイレクト先
   * @param consumerKey OAuth コンシューマキー
   * @param consumerSecretKey OAuth コンシューマシークレットキー
   */
  constructor(
    requestTokenUrl: string,
    requestAuthorizeUrl: string,
    requestAccessTokenUrl: string,
    redirectUri: string,
    consumerKey: string,
    consumerSecretKey: string,
  ) {
    this.requestTokenUrl = requestTokenUrl
    this.requestAuthorizeUrl = requestAuthorizeUrl
    this.requestAccessTokenUrl = requestAccessTokenUrl
    this.redirectUri = redirectUri
    this.consumerKey = consumerKey
    this.consumerSecretKey = consumerSecretKey

    this.oauth1Client = new OAuth1Client(this.consumerKey, this.consumerSecretKey)
  }

  /**
   * 3-Legged OAuth Flow を開始する。
   */
  public async startOAuthFlow() {
    // Step 1: POST oauth/request_token
    const response = await this.requestToken(this.consumerKey, this.redirectUri)
    Logger.info(`oauth_token: ${response.oauth_token}`)

    // Step 2: GET oauth/authorize
    this.authorize(response.oauth_token)
  }

  /**
   * OAuth トークン取得処理を開始する。
   *
   * @param consumerKey コンシューマーキー
   * @param redirectUri 認可結果を通知する際のリダイレクト先
   * @protected
   */
  protected async requestToken(consumerKey: string, redirectUri: string) {
    const data = {
      oauth_callback: redirectUri,
    }
    const response = await this.sendRequest(
      this.requestTokenUrl,
      'post',
      {
        Authorization: this.makeAuthorizationHeader(this.requestTokenUrl, 'post', data),
      },
      {},
    )
    Logger.info(`response data: ${response.data}`)
    const urlSearchParams = new URLSearchParams(response.data)
    return Object.fromEntries(urlSearchParams) as OAuthRequestTokenResponse
  }

  /**
   * OAuth 認可処理を開始する。
   *
   * @param oauthToken OAuthトークン
   * @protected
   */
  protected authorize(oauthToken: string) {
    const oAuthOptions = {
      oauth_token: oauthToken,
    }
    const urlQueryParams = qs.stringify(oAuthOptions, { addQueryPrefix: true })
    window.open(`${this.requestAuthorizeUrl}${urlQueryParams}`, '_system')
  }

  /**
   * access token と access token secret を取得する。
   */
  async getAccessToken(oauthToken: string, oauthVerifier: string) {
    Logger.debug(`oauthToken: ${oauthToken} oauthVerifier: ${oauthVerifier}`)
    let response = null
    if (this.consumerKey) {
      response = await this.requestAccessToken(this.consumerKey, oauthToken, oauthVerifier)
    }
    return response
  }

  /**
   * アクセストークンの取得処理を開始する。
   *
   * @param consumerKey OAuth コンシューマキー
   * @param oAuthToken OAuth トークン
   * @param oAuthVerifier OAuth Verifier
   * @protected
   */
  protected async requestAccessToken(
    consumerKey: string,
    oAuthToken: string,
    oAuthVerifier: string,
  ) {
    const data = {
      oauth_consumer_key: consumerKey,
      oauth_token: oAuthToken,
      oauth_verifier: oAuthVerifier,
    }
    const response = await this.sendRequest(
      this.requestAccessTokenUrl,
      'post',
      {
        Authorization: this.makeAuthorizationHeader(this.requestAccessTokenUrl, 'post', data),
      },
      data,
    )
    Logger.info(`response data: ${response.data}`)
    const urlSearchParams = new URLSearchParams(response.data)
    return Object.fromEntries(urlSearchParams) as OAuthRequestAccessTokenResponse
  }

  /**
   * OAuth AuthorizationHeader を生成する。
   * @see OAuth1Client#makeAuthorizationHeader
   * @param url 接続先のURL
   * @param method HTTPメソッド
   * @param data リクエストボディ
   * @param accessToken OAuth アクセストークン
   * @param accessTokenSecret OAuth アクセストークンシークレット
   * @private
   */
  private makeAuthorizationHeader(
    url: string,
    method: HTTPMethodType,
    data?: HTTPRequestDataType,
    accessToken?: string,
    accessTokenSecret?: string,
  ) {
    const oauthToken =
      accessToken && accessTokenSecret ? { accessToken, accessTokenSecret } : undefined
    return this.oauth1Client?.makeAuthorizationHeader(url, method, data, oauthToken)
  }

  /**
   * HTTPリクエストを送信する。
   *
   * @param url リクエスト先
   * @param method HTTP メソッド
   * @param headers HTTP ヘッダ
   * @param data リクエストボディ
   * @param serializer リクエストデータのエンコード方法
   * @private
   */
  // eslint-disable-next-line class-methods-use-this
  private sendRequest(
    url: string,
    method: HTTPMethodType,
    headers: Record<string, string>,
    data?: Record<string, string>,
    serializer?: HTTPSerializerType,
  ) {
    return this.oauth1Client?.sendRequest(url, method, headers, data, serializer)
  }
}
