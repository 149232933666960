






import { defineComponent } from '@vue/composition-api'
import MypageBlockedUserListSection from '@/components/MypageBlockedUserListPage/MypageBlockedUserListPane/MypageBlockedUserListSection.vue'

export default defineComponent({
  name: 'MypageBlockedUserListPane',
  components: { MypageBlockedUserListSection },
})
