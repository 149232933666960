import { computed } from '@vue/composition-api'
import StoreUtil from '@/store/StoreUtil'
import I18n from '@/locales/I18n'

export default function useEcErrorHandling() {
  const mypagePageStore = StoreUtil.useStore('MypagePageStore')

  /**
   * ECサイト連携のエラー処理の機能を提供する。
   */
  const ecConnectErrorData = computed(() => {
    switch (mypagePageStore.ownOrganization.value?.ecConnectBatchError) {
      case '26_001':
        return {
          title: '',
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.noCustomersExist.message',
          ),
          hasContact: false,
        }
      case '26_002':
        return {
          title: '',
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.limitExceeded.message',
          ),
          hasContact: false,
        }
      case '26_003':
        return {
          title: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.unexpectedError.title',
          ),
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.unexpectedError.message',
          ),
          hasContact: true,
        }
      case '26_004':
        return {
          title: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.authError.title',
          ),
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.authError.message',
          ),
          hasContact: true,
        }
      case '26_005':
        return {
          title: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.noCustomersExistWithLinkedGuest.title',
          ),
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.customerSearch.noCustomersExistWithLinkedGuest.message',
          ),
          hasContact: false,
        }
      case '26_101':
        return {
          title: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.rankUpdate.limitExceeded.title',
          ),
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.rankUpdate.limitExceeded.message',
          ),
          hasContact: false,
        }
      case '26_102':
        return {
          title: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.rankUpdate.unexpectedError.title',
          ),
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.rankUpdate.unexpectedError.message',
          ),
          hasContact: true,
        }
      case '26_103':
        return {
          title: I18n.tc('MypagePage.MypageEcSiteConnectPage.errors.rankUpdate.authError.title'),
          description: I18n.tc(
            'MypagePage.MypageEcSiteConnectPage.errors.rankUpdate.authError.message',
          ),
          hasContact: true,
        }
      default:
        return {
          title: '',
          description: '',
          hasContact: true,
        }
    }
  })

  return {
    ecConnectErrorData,
  }
}
