import { computed, Ref, ref } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import MissionProgressDocument, {
  MissionCodeType,
} from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import useMissionTerm from '@/store/hook/mission/useMissionTerm'
import { EVENT_CHECK_IN_MISSION_CODE_LIST, ROUND_CHECK_IN_MISSION_CODE_LIST } from '@/util/Const'

/**
 * ミッション達成状況を操作するための処理を取得する。
 */
export default function useMissionProgress() {
  /**
   * 今シーズンのミッション達成状況一覧
   */
  const thisSeasonMissionProgresses: Ref<Array<MissionProgressDocument>> = ref([])

  // Collection modules
  const missionProgressCollectionModule = CollectionModule.createStore(MissionProgressDocument)

  // hook
  const { getTargetYearMissionTerm, getThisSeasonYear } = useMissionTerm()

  /**
   * 取得したミッション達成状況一覧。
   */
  const missionProgresses = computed(() => missionProgressCollectionModule.data)

  /**
   * シーズンラウンドチェックインとイベントチェックインのミッション達成状況一覧。
   */
  const seasonRoundAndEventCheckInProgresses = computed(() => {
    const seasonRoundAndEventCheckInMissionCodeList = [
      ...ROUND_CHECK_IN_MISSION_CODE_LIST,
      ...EVENT_CHECK_IN_MISSION_CODE_LIST,
    ]
    return missionProgressCollectionModule.data.filter((mission) =>
      (seasonRoundAndEventCheckInMissionCodeList as Array<string>).includes(
        mission.missionCode || '',
      ),
    )
  })

  /**
   * 今シーズン：シーズンラウンドチェックインとイベントチェックインのミッション達成状況一覧。
   */
  const thisSeasonRoundAndEventCheckInProgresses = computed(() => {
    const seasonRoundAndEventCheckInMissionCodeList = [
      ...ROUND_CHECK_IN_MISSION_CODE_LIST,
      ...EVENT_CHECK_IN_MISSION_CODE_LIST,
    ]
    return thisSeasonMissionProgresses.value.filter((mission) =>
      (seasonRoundAndEventCheckInMissionCodeList as Array<string>).includes(
        mission.missionCode || '',
      ),
    )
  })

  /**
   * ミッションコード毎に分類されたミッション達成状況
   */
  const missionProgressesByMissionCode = computed(() => {
    const missionProgressesMap: Record<MissionCodeType, MissionProgressDocument> = {} as Record<
      MissionCodeType,
      MissionProgressDocument
    >
    missionProgresses.value.forEach((missionProgress) => {
      if (missionProgress.missionCode) {
        missionProgressesMap[missionProgress.missionCode] = missionProgress
      }
    })
    return missionProgressesMap
  })

  // methods
  /**
   * ミッション達成状況一覧を取得する。
   */
  const fetchMissionProgresses = async (year: number, isAppendData = false) => {
    // 選択年度のミッション期間を取得
    const missionTerm = getTargetYearMissionTerm(year)

    // 選択年度のミッション達成状況を取得
    const result = await missionProgressCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/mission/progress/user/${LoginStore.value.userId}`,
      query: {
        filter: {
          startDate: { $gte: missionTerm.startDate },
          endDate: { $lte: missionTerm.endDate },
        },
        sort: 'startDate',
      },
      isAppendData,
    })
    if (result.isSuccess && year === getThisSeasonYear()) {
      // 対象年が、今シーズンの場合
      thisSeasonMissionProgresses.value.splice(0)
      thisSeasonMissionProgresses.value.push(...result.data)
    }

    return result
  }

  /**
   * 永久ポイントミッション達成状況一覧を取得する。
   * endDateに遠い将来の日付がセットされており、ミッション表示対象年度の範囲を指定して取得ができないため、ミッションIDを指定して取得する
   */
  const fetchPermanentPointMissionProgresses = (isAppendData = false) =>
    missionProgressCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/${
        LoginStore.value.orgId
      }/mission/progress/user/${LoginStore.value.userId}`,
      query: {
        filter: {
          missionId: {
            $in: [
              'registered-favorite-team',
              'registered-favorite-player',
              'registered-age',
              'registered-area',
              'registered-gender',
            ],
          },
        },
      },
      isAppendData,
    })

  /**
   * missionProgressCollectionModuleをクリアする
   */
  const clearMissionProgressCollectionModule = () => {
    missionProgressCollectionModule.clearData()
  }

  /**
   * 取得したミッション達成状況をクリアする
   */
  const clearMissionProgresses = () => {
    clearMissionProgressCollectionModule()
    thisSeasonMissionProgresses.value.splice(0)
  }

  return {
    fetchMissionProgresses,
    fetchPermanentPointMissionProgresses,
    missionProgressesByMissionCode,
    seasonRoundAndEventCheckInProgresses,
    thisSeasonRoundAndEventCheckInProgresses,
    clearMissionProgresses,
    clearMissionProgressCollectionModule,
    missionProgresses,
  }
}
