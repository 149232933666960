




































































































import {
  computed,
  defineComponent,
  nextTick,
  onUnmounted,
  reactive,
  ref,
  Ref,
  toRef,
  watch,
} from '@vue/composition-api'
import dayjs from 'dayjs'
import DeviceInfo from '@/util/DeviceInfo'
import I18n from '@/locales/I18n'
import SelectYearSection from '@/components/RaceListPage/RaceListPane/SelectYearSection.vue'
import RaceListSection from '@/components/RaceListPage/RaceListPane/RaceListSection.vue'
import RaceInformationSection from '@/components/RaceListPage/RaceListPane/RaceInformationSection.vue'
import FlowLineToPaidPlanModalSection from '@/components/common/modal/FlowLineToPaidPlanModalSection.vue'
import InformationToggleButtonParts from '@/components/RaceListPage/RaceListPane/parts/InformationToggleButtonParts.vue'
import ChampionshipDocument from '@/store/stores/collectionModule/documents/championship/ChampionshipDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import StoreUtil from '@/store/StoreUtil'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import Const, { RaceYearType } from '@/util/Const'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import LocalCache from '@/util/localcache/LocalCache'
import Logger from '@/util/logger/Logger'
import RaceDocument from '@/store/stores/collectionModule/documents/race/RaceDocument'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import { Response } from '@/store/stores/collectionModule/CollectionTypes'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import useRacePointRadio from '@/components/RaceListPage/hook/useRacePointRadio'
import RadioDataDocument from '@/store/stores/collectionModule/documents/Radio/RadioDataDocument'
import CircuitModeSection from '@/components/HomePage/HomePane/CircuitModeSection.vue'
import RaceTimetableSection from '@/components/RaceListPage/RaceListPane/RaceTimetableSection.vue'
import RaceListHeaderSection from '@/components/RaceListPage/RaceListPane/RaceListHeaderSection.vue'
import RaceListCloseTimetableButtonParts from '@/components/RaceListPage/RaceListPane/parts/RaceListCloseTimetableButtonParts.vue'

/**
 * レース一覧画面ペインのコンポーネント
 */
export default defineComponent({
  name: 'RaceListPane',
  components: {
    RaceListCloseTimetableButtonParts,
    RaceListHeaderSection,
    RaceTimetableSection,
    CircuitModeSection,
    SelectYearSection,
    RaceListSection,
    RaceInformationSection,
    FlowLineToPaidPlanModalSection,
    InformationToggleButtonParts,
  },
  props: {
    /**
     * RaceInfoリンクボタン表示
     */
    enabledRaceInfo: {
      type: Boolean,
      default: false,
    },
    /**
     * レース一覧カルーセル
     */
    enabledRaceListCarousel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TopPageStore
    const topPageStore = StoreUtil.useStore('TopPageStore')
    // NotificationStore
    const notificationStore = StoreUtil.useStore('NotificationStore')

    const {
      fetchTopPageData,
      currentChampionship,
      sortedChampionships,
      highlightsForPreLoad,
      contentsInfo,
      qualifyingRaceStartEventList,
      clearTopPageData,
      fetchRaceRankData,
      getRankingCardData,
      clearRankData,
      fetchRacePointVideoData,
      fetchRacePointRadioData,
      racePointRadioData,
      racePointVideoData,
      radioDataStore,
    } = topPageStore
    const { fetchNotificationData } = notificationStore

    const header = ref<HTMLElement | null>(null)
    const state = reactive({
      contentHeight: 'auto',
    })
    const forPc = DeviceInfo.isForPC()

    // リアルタイムメッセージ
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()
    // 無線
    const { playRadioDataAudio } = useRacePointRadio()

    /**
     * 大会ロゴ画像の署名URLを取得する
     */
    const championshipLogoPath = computed(() =>
      CloudFrontUtil.getSignedUrl(topPageStore.currentChampionship?.value?.championshipLogo),
    )

    /**
     * レースにInformation情報が含まれるか
     */
    const hasCurrentRaceInformation = ref<boolean>(false)

    /**
     * Information 表示トグル
     */
    const showInformation = ref<boolean>(false)
    const toggleInformation = () => {
      showInformation.value = !showInformation.value
    }

    /**
     * コンテンツの高さを設定する
     */
    const contentStyleHeight = computed(() => {
      if (topPageStore.topPageState.showTimetable) {
        return '100%'
      }

      return state.contentHeight
    })

    /**
     * 有料プランへのアップグレード案内、または非公開コンテンツへのアクセスを通知するモーダル フラグ
     */
    const flowLineToPaidPlanModalEnabled = ref(false)
    /**
     * 有料プランへのアップグレードを案内するモーダルに提供するコンテンツ
     */
    const flowLineToPaidPlanModalContents = {
      title: I18n.tc('common.flowLineToPaidPlan.title'),
      message: I18n.tc('common.flowLineToPaidPlan.message'),
      submitText: I18n.tc('common.flowLineToPaidPlan.submitText'),
      link:
        I18n.locale === 'ja'
          ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA
          : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN,
    }
    /**
     * 以下を表示する
     *
     * 無料会員の場合: 有料プランへのアップグレードを案内
     * 有料会員の場合: 非公開コンテンツである旨のメッセージ（クーポンプランも含む）
     */
    const showFlowLineToPaidPlanModal = () => {
      if (!ContractInfoStore.value.isFreePlan) {
        // 有料会員の場合
        Object.assign(flowLineToPaidPlanModalContents, {
          title: I18n.tc('common.PrivateContent.title'),
          message: I18n.tc('common.PrivateContent.message'),
          submitText: '',
          link: '',
        })
      }
      // モーダルを表示する
      flowLineToPaidPlanModalEnabled.value = true
    }

    /**
     * 有料プランへのアップグレードを案内、または非公開コンテンツへのアクセス通知するモーダル 非表示
     */
    const hideFlowLineToPaidPlanModal = () => {
      flowLineToPaidPlanModalEnabled.value = false
    }

    /**
     * アニメーション用にコンテンツの高さを制御
     */
    const manageContentHeight = () => {
      if (!header.value) {
        state.contentHeight = 'auto'
        return
      }
      const headerElmRect = header.value.getBoundingClientRect()
      if (forPc) {
        state.contentHeight = `${
          236 + (window.innerHeight - 48 - 44 - 236) / 2 + headerElmRect.height / 2
        }px`
      } else {
        state.contentHeight = `${headerElmRect.height + 52 + 185}px`
      }
    }

    /**
     * 全てのセッションの表示/非表示を切り替える
     */
    const toggleMatchList = () => {
      topPageStore.toggleTimetable()
      nextTick(() => {
        manageContentHeight()
      })
    }

    /**
     * 開始日ごとに分類されたレース情報を取得する
     */
    const getRaceListByStartDate = (races: Array<RaceDocument>) =>
      races.reduce((acc, cur) => {
        const key = cur.startDate ? dayjs(cur.startDate).tz('Asia/Tokyo').format('YYYY-MM-DD') : ''
        if (!acc[key]) {
          acc[key] = [cur]
        } else {
          acc[key].push(cur)
        }
        return acc
      }, {} as Record<string, Array<RaceDocument>>)

    /**
     * 開始日ごとに分類された全レース一覧
     */
    const raceListByStartDate = computed(() => {
      const allRaces = [
        ...topPageStore.sessionsWithOutRace.value,
        ...topPageStore.mainRace.value,
      ].sort((a, b) => (a.startDate || 0) - (b.startDate || 0))
      // 開始日時が存在するレース
      const racesWithStartDate = getRaceListByStartDate(allRaces.filter((race) => race.startDate))
      // 開始日時が存在しないレース
      const racesWithoutStartDate = getRaceListByStartDate(
        allRaces.filter((race) => !race.startDate),
      )
      // 開始日時が存在するレースを先に表示する
      return { ...racesWithStartDate, ...racesWithoutStartDate }
    })

    /**
     * Information表示状態を監視し、Informationを非表示にした場合に以下の処理を行う
     * 無線交信データの音声の再生を停止
     * スクロール位置をリセット
     */
    watch(
      () => showInformation.value,
      (newShowInformation) => {
        if (!newShowInformation) {
          // 無線交信データの音声の再生を停止
          radioDataStore.pauseRadioAudio()

          // スクロール位置をリセット
          const showInformationElement = document.querySelector(
            '.race-information-section',
          ) as HTMLDivElement
          showInformationElement.scrollTop = 0
        }
      },
    )

    watch(currentChampionship, () => {
      nextTick(() => {
        manageContentHeight()
      })
    })

    onUnmounted(() => {
      // レース、お知らせに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
      clearRankData()
      radioDataStore.clearRadioData()
      radioDataStore.clearAudioPlayer()
    })

    return {
      header,
      state,
      forPc,
      contentStyleHeight,
      currentYear: toRef(topPageStore.topPageState, 'raceYear'),
      yearList: toRef(topPageStore.topPageState, 'yearList'),
      fetchTopPageData,
      currentChampionship: currentChampionship as Ref<ChampionshipDocument>,
      championshipLogoPath,
      championships: sortedChampionships as Ref<Array<ChampionshipDocument>>,
      fetchRaces: topPageStore.fetchRaces,
      fetchDisplayedChampionshipMovieInfo: topPageStore.fetchDisplayedChampionshipMovieInfo,
      qualifyingRaces: topPageStore.qualifyingRaceList,
      sessionsWithOutRace: topPageStore.sessionsWithOutRace as Ref<Array<RaceDocument>>,
      finalRaces: topPageStore.mainRace as Ref<Array<RaceDocument>>,
      raceListByStartDate,
      clearTopPageData,
      highlightsForPreLoad,
      contentsInfo,
      qualifyingRaceStartEventList,
      initRTM,
      subscribeRTM,
      unSubscribeRTM,
      showTimetable: toRef(topPageStore.topPageState, 'showTimetable'),
      toggleMatchList,
      hasCurrentRaceInformation,
      toggleInformation,
      showInformation,
      fetchRaceRankData,
      getRankingCardData,
      clearRankData,
      playRadioDataAudio,
      fetchRacePointVideoData,
      racePointVideoData,
      fetchRacePointRadioData,
      racePointRadioData,
      radioDataStore,
      flowLineToPaidPlanModalEnabled,
      flowLineToPaidPlanModalContents,
      showFlowLineToPaidPlanModal,
      hideFlowLineToPaidPlanModal,
      fetchNotificationData,
    }
  },
  async created() {
    this.radioDataStore.createAudioPlayer()

    // 初期表示は本年のレース情報を表示する
    await this.fetchTargetYearRaceData()
    this.preLoadRaceMovieData()
    // レースに関連するリアルタイムメッセージを受信
    // (予選のQ1-A,Q1-B,Q2 のスタートイベントが登録された場合にレース一覧に反映するため)
    await this.initRTM()
    this.subscribeRTM('highlight', async (data: RTMCallbackParamType) => {
      if (this.$router.currentRoute.name === 'RaceVideoPage') return
      Logger.debug(
        `RaceListPane#subscribeRTM: Receive highlight event. event: ${JSON.stringify(data)}`,
      )
      await this.fetchTopPageData(this.currentYear)
      this.fetchDisplayedChampionshipMovieInfo()
      this.preLoadRaceMovieData()
    })
    // お知らせのリアルタイムメッセージを受信
    this.subscribeRTM('information', async (data: RTMCallbackParamType) => {
      Logger.debug(
        `RaceListPane#subscribeRTM: Receive notification event. event: ${JSON.stringify(data)}`,
      )
      this.fetchNotificationData()
    })
  },
  methods: {
    /**
     * 指定された年度のTOPページのデータを取得する。
     * @param raceYear 年度
     * @return 処理完了を待機するためのPromise
     */
    async fetchTargetYearRaceData(raceYear?: RaceYearType) {
      this.clearTopPageData()
      const results = await this.fetchTopPageData(raceYear)
      const failedResponse = [...results].find((response) => !response.isSuccess)
      if (failedResponse) {
        await MessageDialogStore.value.open({
          title: this.$t('RaceListPage.errors.fetchTopPageDataError.title') as string,
          message: this.$t('RaceListPage.errors.fetchTopPageDataError.message') as string,
          errorApiResponse: failedResponse.response,
        })
      } else {
        this.preLoadRaceMovieData()
        await this.change(this.currentChampionship)
      }
    },
    /**
     * レース動画の開始位置と、予選の各スタートイベントの動画の先読みを行う。
     */
    preLoadRaceMovieData() {
      const preLoadItems = this.highlightsForPreLoad(
        this.qualifyingRaceStartEventList,
        this.contentsInfo,
      )
      LocalCache.preLoad(preLoadItems).then(() => {
        Logger.info('RaceListPane#created: Success to preload start event movie data.')
      })
    },
    /**
     * TOP画面で大会が選択された際に呼び出される。
     * 選択された大会のレース情報を取得する。
     * @param championship 変更後の大会
     */
    async change(championship: ChampionshipDocument) {
      if (!championship || !championship.id) {
        return
      }

      /** レース切り替え時にInformation部を閉じる */
      this.showInformation = false
      /** レースにInformation情報が含まれるかのフラグをリセット */
      this.hasCurrentRaceInformation = false

      /** 選択されたレース大会に変更 */
      this.currentChampionship = championship
      const results = await this.fetchRaces(championship.id)
      const failedResponse = [...results].find(
        (response: Response<RaceDocument | ContentsInfoDocument | HighlightDocument>) =>
          !response.isSuccess,
      )
      if (failedResponse) {
        await MessageDialogStore.value.open({
          title: this.$tc('RaceListPage.errors.fetchRacesError.title'),
          message: this.$tc('RaceListPage.errors.fetchRacesError.message'),
          errorApiResponse: failedResponse.response,
        })
      } else {
        this.preLoadRaceMovieData()
      }

      const relatedRaceDataResults = await Promise.all([
        // レースのInformation情報を取得
        this.fetchRaceRankData(championship, this.finalRaces[0], this.qualifyingRaces[0]),
        this.fetchRacePointVideoData(this.finalRaces[0], this.sessionsWithOutRace),
        this.fetchRacePointRadioData(),
        // メイン映像とオンボード映像（と英語の中継映像）の動画情報を取得
        this.fetchDisplayedChampionshipMovieInfo(),
      ])

      const fetchMovieInfoFailed =
        relatedRaceDataResults?.[3] &&
        relatedRaceDataResults[3].flat().find((response) => !response.isSuccess)
      if (fetchMovieInfoFailed) {
        // 動画情報取得に失敗した場合再生ボタンを押せなくなるため、エラーを表示する
        await MessageDialogStore.value.open({
          title: this.$tc('RaceListPage.errors.fetchMovieInfoError.title'),
          message: this.$tc('RaceListPage.errors.fetchMovieInfoError.message'),
          errorApiResponse: fetchMovieInfoFailed?.response,
        })
      }

      this.hasCurrentRaceInformation =
        this.getRankingCardData.length > 0 ||
        this.racePointVideoData.length > 0 ||
        this.racePointRadioData.length > 0
    },
    /**
     * 年度が変更された場合に呼び出される。
     * 指定された年度の大会/レース情報を取得する。
     */
    yearChange(raceYear: RaceYearType) {
      this.fetchTargetYearRaceData(raceYear)
    },
    /**
     * 無線交信データの音声を再生する。
     * @param radioData 無線交信データ
     */
    async playRadioAudio(radioData: RadioDataDocument) {
      await this.playRadioDataAudio(radioData)
    },
  },
})
