


































import { defineComponent } from '@vue/composition-api'
import MypagePointExpirationDateTableSection from '@/components/MypagePointExpirationDatePage/MypagePointExpirationDatePane/MypagePointExpirationDateTableSection.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'

export default defineComponent({
  name: 'MypagePointExpirationDatePane',
  components: {
    AtomRouterButton,
    AtomIconLinkButton,
    MypagePointExpirationDateTableSection,
  },
  setup() {
    const pointDescriptionPageUrl = '#'
    return {
      pointDescriptionPageUrl,
    }
  },
})
