








































import { computed, defineComponent, PropType, reactive, watch, ref } from '@vue/composition-api'
import MissionCheckInSection from '@/components/MissionPage/MissionListPane/MissionCheckInSection.vue'
import MissionYearSelectorParts from '@/components/MissionPage/MissionListPane/parts/MissionYearSelectorParts.vue'
import MembersPointSection from '@/components/MypageTopPage/MembershipCardPane/MembersPointSection.vue'
import MissionProgressDocument from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'
import MissionListTabsParts from '@/components/MissionPage/MissionListPane/parts/MissionListTabsParts.vue'

/**
 * ミッションページのヘッダー
 */
export default defineComponent({
  name: 'MissionListHeaderSection',
  components: {
    MissionListTabsParts,
    MembersPointSection,
    MissionYearSelectorParts,
    MissionCheckInSection,
  },
  props: {
    /**
     * ミッション一覧画面で表示する年度
     */
    selectedMissionYear: {
      type: Number,
      required: true,
    },
    /**
     * 今シーズン：開催中のラウンドチェックインまたはイベントチェックインミッション一覧
     */
    checkInMissionsBeingHeldForThisSeason: {
      type: Array as PropType<MissionProgressDocument[]>,
      required: true,
    },
    successfulCheckIn: {
      type: Boolean,
    },
    isFixed: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const loginStore = StoreUtil.useStore('LoginStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const { getBadgeDisplayYearsInfo, getCurrentRank } = useMembershipCard()
    const { isFreePlan } = contractInfoStore
    const wrapper = ref<HTMLElement | null>(null)
    const year = ref<HTMLElement | null>(null)

    const state = reactive({
      showCheckIn: true,
    })

    const checkInStyle = computed(() => {
      if (!props.isFixed) {
        return {}
      }
      const wrapperHeight = wrapper.value?.getBoundingClientRect().height || 0
      const yearHeight = year.value?.getBoundingClientRect().height || 0
      const fixedHeaderHeight = wrapperHeight + yearHeight
      return {
        top: `${fixedHeaderHeight}px`,
      }
    })

    /**
     * バッジリストを取得する
     */
    const getBadgeList = () => {
      const results = []
      // sfgo-dev-supporterをロールに持つ場合、開発サポーターのバッジを付与する
      if (loginStore?.userRoles.includes('sfgo-dev-supporter')) {
        results.push({
          badgeType: 'supporter',
          label: '開発サポーター',
        })
      }
      // 登録日からの経過年数に応じたバッジ情報を取得する
      const elapsedYearResult = getBadgeDisplayYearsInfo(
        contractInfoStore?.ownOrganization.value?._createdDate,
      )
      if (elapsedYearResult) {
        results.push(elapsedYearResult)
      }
      return results
    }

    /**
     * ユーザーデータ
     */
    const userViewData = computed(() => ({
      name: mypagePageStore.user.value?.additionalData?.userDisplayName || '',
      image: CloudFrontUtil.getSignedUrl(mypagePageStore.user.value?.userPicture) || '',
      userRole: loginStore?.userRoles.find((r) =>
        Const.SFGO_ROLES.filter((v) => v !== 'sfgo-dev-supporter').includes(r),
      ),
      badgeList: getBadgeList(),
      isFreePlan,
    }))

    /**
     * 会員ランクデータ
     */
    const membersRankData = computed(() => ({
      point: mypagePageStore.currentOwnedPoints.value ?? 0,
      currentRank: getCurrentRank(mypagePageStore.currentOwnedPoints.value),
    }))

    const handleShowCheckInClicked = () => {
      state.showCheckIn = true
    }
    const handleCloseCheckInClicked = () => {
      state.showCheckIn = false
    }

    const handleCheckInClicked = () => {
      context.emit('onClickCheckIn')
    }

    /**
     * ミッション一覧画面の年度を変更
     */
    const onChangeMissionYear = (changedYear: number) => {
      context.emit('onChangeMissionYear', changedYear)
    }

    watch(
      () => props.isFixed,
      (val) => {
        state.showCheckIn = !val
      },
    )
    return {
      state,
      wrapper,
      year,
      checkInStyle,
      handleShowCheckInClicked,
      handleCloseCheckInClicked,
      handleCheckInClicked,
      userViewData,
      membersRankData,
      onChangeMissionYear,
    }
  },
})
