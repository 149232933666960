



















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import GlobalNavigationPane from '@/components/common/GlobalNavigation/GlobalNavigationPane.vue'
import MypageBlockedUserListPane from '@/components/MypageBlockedUserListPage/MypageBlockedUserListPane.vue'

export default defineComponent({
  name: 'MypageBlockedUserListPage',
  components: { MypageBlockedUserListPane, GlobalNavigationPane, SubHeaderSection },
  setup() {
    // TODO: ブロックしたユーザー一覧対応時に消す
    const isIncludeNextRelease = process.env.VUE_APP_INCLUDE_NEXT_RELEASE === 'true'
    return {
      isIncludeNextRelease,
    }
  },
})
