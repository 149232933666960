



















import { defineComponent } from '@vue/composition-api'
import MypagePointMinusIcon from '@/components/MypagePointHistoryPage/MypagePointHistoryPane/parts/icons/MypagePointMinusIcon.vue'
import MypagePointPlusIcon from '@/components/MypagePointHistoryPage/MypagePointHistoryPane/parts/icons/MypagePointPlusIcon.vue'

export default defineComponent({
  name: 'MypagePointItemParts',
  components: { MypagePointPlusIcon, MypagePointMinusIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
