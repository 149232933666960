




























































import { computed, defineComponent, ref } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import i18n from '@/locales/I18n'
import LocationErrorSection from '@/components/common/modal/LocationErrorSection.vue'
import ModalCouponCodeInputParts from '@/components/common/modal/parts/ModalCouponCodeInputParts.vue'
import OneTimePassRegisterSection from '@/components/common/modal/OneTimePassRegisterSection.vue'

/**
 * 無料会員が有料会員コンテンツへアクセスを試みた時に表示するモーダル
 * 非公開コンテンツへのアクセスを試みた場合にもこちらのモーダルを表示する
 */
export default defineComponent({
  name: 'FlowLineToPaidPlanModalSection',
  components: {
    OneTimePassRegisterSection,
    ModalCouponCodeInputParts,
    LocationErrorSection,
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomIconLinkButton,
    AtomInputButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    submitText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    /**
     * モーダル内に表示するコンテンツ
     * - 通常： default
     * - 位置情報エラー: locationError
     * - プラン登録: planRegistration
     */
    const displayMode = ref('default')

    // クーポンコードがエラーかどうか
    const hasCodeError = ref(false)

    // 閉じるボタンのラベルを解す
    const closeButtonLabel = computed(() =>
      displayMode.value === 'default' ? i18n.tc('common.close') : i18n.tc('common.cancel'),
    )

    /**
     * 使用するボタンが押下されたときの処理
     */
    const handleUseClicked = () => {
      emit('onClickUse')
    }

    // TODO: ディーラークーポン対応時に削除
    const isIncludeNextRelease = process.env.VUE_APP_INCLUDE_NEXT_RELEASE === 'true'

    return {
      displayMode,
      hasCodeError,
      closeButtonLabel,
      isIncludeNextRelease,
      handleUseClicked,
    }
  },
  methods: {
    emitClose(): void {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      this.$emit('close')
    },
  },
})
