import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import Logger from '@/util/logger/Logger'
import Const from '@/util/Const'

/**
 * 組織データを操作するための処理を提供する。
 */
export default function useOrganization() {
  // Collection modules
  const organizationCollectionModule = CollectionModule.createStore(OrganizationDocument)

  /**
   * 組織データを取得する。
   * expand: 'contract' を指定してプラン情報も取得する。
   * @return APIレスポンス
   */
  const fetchOrganizations = () =>
    organizationCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/organization`,
      query: {
        expand: 'contract',
      },
    })

  /**
   * 取得した組織情報
   */
  const organizations = computed(() => organizationCollectionModule.data)

  /**
   * ログインユーザーに紐づいた組織情報
   */
  const ownOrganization = computed(() =>
    organizations.value.find((organization) => organization.isOwnOrgData),
  )

  /**
   * ログインユーザーに紐づいた組織情報のpermissionsの配列を取得
   */
  const ownOrganizationPermission = computed(() => ownOrganization?.value?.permissions)

  // methods
  /**
   * 組織情報を更新する
   */
  const updateOrganization = async (organizationData: OrganizationDocument) =>
    organizationCollectionModule.save(organizationData, {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/organization/${
        organizationData._organization
      }`,
    })

  /**
   * 組織データを定期的に取得する。
   * expand: 'contract' を指定してプラン情報も取得する。
   * @param maxExecCount 最大実行回数
   */
  const pollingOrganizations = (maxExecCount = 10) => {
    let count = 0
    const timerId = window.setInterval(() => {
      fetchOrganizations().then(() => {
        Logger.debug(`useOrganization#pollingOrganizations: Polling to fetch organizations.`)
      })

      count += 1
      if (count === maxExecCount || !ownOrganization.value?.ec?.linkRequest) {
        // 最大実行回数に達したら または 連携が完了したらポーリングを停止する
        clearInterval(timerId)
      }
    }, Const.ORGANIZATION_POLING_INTERVAL)
  }

  /**
   * 取得した組織情報をクリアする
   */
  const clearOrganizations = () => {
    organizationCollectionModule.clearData()
  }

  return {
    fetchOrganizations,
    pollingOrganizations,
    organizations,
    ownOrganization,
    updateOrganization,
    clearOrganizations,
    ownOrganizationPermission,
  }
}
