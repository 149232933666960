


























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MypagePointExpirationDateTableSection',
  setup() {
    const dummyData = [
      {
        id: '1',
        point: 200,
        expirationDate: '2025/01/31',
      },
      {
        id: '2',
        point: 10,
        expirationDate: '2025/08/03',
      },
      {
        id: '3',
        point: 1000,
        expirationDate: '2026/01/31',
      },
    ]
    return {
      dummyData,
    }
  },
})
