












































































































































































import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  Ref,
} from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import { orderBy } from 'lodash'
import MissionListHeaderSection from '@/components/MissionPage/MissionListPane/MissionListHeaderSection.vue'
import MissionCardSection from '@/components/MissionPage/MissionListPane/MissionCardSection.vue'
import MissionProcessParts from '@/components/MissionPage/MissionListPane/parts/MissionProcessParts.vue'
import MissionGetPointParts from '@/components/MissionPage/MissionListPane/parts/MissionGetPointParts.vue'
import MissionRaceIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionRaceIcon.vue'
import MissionMemberIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionMemberIcon.vue'
import MissionClipIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionClipIcon.vue'
import MissionRadiophoneIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionRadiophoneIcon.vue'
import MissionHoneycombSevenTenParts from '@/components/MissionPage/MissionListPane/parts/MissionHoneycombSevenTenParts.vue'
import MissionShareIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionShareIcon.vue'
import MissionReferralCodeParts from '@/components/MissionPage/MissionListPane/parts/MissionReferralCodeParts.vue'
import MissionCheckListParts from '@/components/MissionPage/MissionListPane/parts/MissionCheckListParts.vue'
import MissionExternalLinkIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionExternalLinkIcon.vue'
import StoreUtil from '@/store/StoreUtil'
import useMissionProgressData, {
  MissionProgressCardType,
  CheckPointType,
} from '@/components/MissionPage/hook/useMissionProgressData'
import MissionProgressDocument from '@/store/stores/collectionModule/documents/mission/MissionProgressDocument'
import MissionMapIcon from '@/components/MissionPage/MissionListPane/parts/icons/MissionMapIcon.vue'
import MissionHoneyCombCircuitParts from '@/components/MissionPage/MissionListPane/parts/MissionHoneyCombCircuitParts.vue'
import MissionHoneycombNineParts from '@/components/MissionPage/MissionListPane/parts/MissionHoneycombNineParts.vue'
import I18n from '@/locales/I18n'
import CheckInFailedModalSection from '@/components/MissionPage/MissionListPane/CheckInFailedModalSection.vue'
import MissionTermParts from '@/components/MissionPage/MissionListPane/parts/MissionTermParts.vue'
import MissionCheckInPointSection from '@/components/MissionPage/MissionListPane/MissionCheckInPointSection.vue'

/* eslint-disable @typescript-eslint/no-var-requires */
const bgImagePath = require('@/assets/img/MissionPage/bg.png')

/**
 * ミッション画面 チェックイン、ミッション達成状況一覧ペイン
 */
export default defineComponent({
  name: 'MissionListPane',
  components: {
    CheckInFailedModalSection,
    MissionTermParts,
    MissionCheckInPointSection,
    MissionHoneycombNineParts,
    MissionHoneyCombCircuitParts,
    MissionMapIcon,
    MissionExternalLinkIcon,
    MissionCheckListParts,
    MissionReferralCodeParts,
    MissionRaceIcon,
    MissionShareIcon,
    MissionHoneycombSevenTenParts,
    MissionRadiophoneIcon,
    MissionClipIcon,
    MissionMemberIcon,
    MissionGetPointParts,
    MissionProcessParts,
    MissionCardSection,
    MissionListHeaderSection,
  },
  setup() {
    const missionPageStore = StoreUtil.useStore('MissionPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const {
      fetchMissionPageData,
      selectedMissionYear,
      checkIn,
      checkInMissionsBeingHeld,
      checkInMissionsBeingHeldForThisSeason,
      isShowCheckInFailedDetailModal,
      currentLocationInfo,
    } = missionPageStore
    const { fetchMypagePageData } = mypagePageStore
    const { getMissionProgressCardData } = useMissionProgressData()
    const loading = inject('loading') as PluginApi
    const header = ref<HTMLElement | null>(null)

    const state = reactive({
      scrollY: 0,
      fixPoint: 227,
      doneFetch: false,
      listStyle: {},
    })
    const isFixed = computed(() => state.scrollY > state.fixPoint)
    const manageListStyle = () => {
      if (header.value === null) {
        return
      }
      const headerHeight = header.value.getBoundingClientRect().bottom
      if (headerHeight === 0) {
        return
      }
      state.listStyle = {
        'padding-top': `${headerHeight - 48 - 10}px`,
      }
    }

    /** Mission達成状況カードデータ */
    const missionProgressCardData = ref([]) as Ref<Array<MissionProgressCardType>>
    /** エリアごとに分類されたイベントチェックインの場所 */
    const checkPointListByArea = ref({}) as Ref<Record<string, Array<CheckPointType>>>
    const successfulCheckIn = ref(false) as Ref<boolean>

    const handlePaneScroll = (event: Event) => {
      const paneElm = event.target as HTMLElement
      state.scrollY = paneElm?.scrollTop
    }

    /**
     * 対象年度のミッション達成状況を取得する
     */
    const fetchMissionData = async (changedYear: number) => {
      selectedMissionYear.value = changedYear
      await fetchMissionPageData(selectedMissionYear.value)
      missionProgressCardData.value = orderBy(
        Object.values(getMissionProgressCardData(changedYear)),
        'order',
      )

      // イベントチェックイン一覧情報をクリアする
      checkPointListByArea.value = {}
      const _missionProgressCardData = getMissionProgressCardData(changedYear)

      if (_missionProgressCardData.CHECKED_IN_BONUS) {
        orderBy(_missionProgressCardData.CHECKED_IN_BONUS.checkPointList, 'startDate')?.forEach(
          (checkinPoint) => {
            const area = checkinPoint.area?.[I18n.locale]
            if (!area) {
              return
            }
            if (checkPointListByArea.value[area]) {
              checkPointListByArea.value[area].push(checkinPoint)
            } else {
              checkPointListByArea.value[area] = [checkinPoint]
            }
          },
        )
      }
    }

    /**
     * チェックインの処理
     */
    const handleCheckInClicked = async () => {
      const loader = loading.show()
      const checkInResult = await checkIn()
      if (checkInResult) {
        successfulCheckIn.value = true
        await Promise.all([
          fetchMissionData(selectedMissionYear.value),
          fetchMypagePageData('', true),
        ])
      }
      loader.hide()
    }

    /**
     * チェックイン失敗詳細モーダル非表示
     */
    const hideCheckInFailedDetailModal = () => {
      isShowCheckInFailedDetailModal.value = false
      currentLocationInfo.value = null
    }

    onMounted(async () => {
      const headerElm = document.querySelector('.header-pane') as HTMLElement
      const pointResultElm = document.querySelector('.mission-list-header__result') as HTMLElement
      state.fixPoint =
        pointResultElm.getBoundingClientRect().top - headerElm.getBoundingClientRect().height

      /**
       * ミッション一覧画面に必要なデータを取得する
       * - 対象年度のミッション達成状況
       * - 保有ポイント（マイページストアの情報を最新にする）
       */
      await Promise.all([
        fetchMissionData(selectedMissionYear.value),
        fetchMypagePageData('', true),
      ])
      manageListStyle()
      state.doneFetch = true

      window.addEventListener('resize', manageListStyle)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', manageListStyle)
    })

    return {
      bgImagePath,
      state,
      isFixed,
      header,
      handlePaneScroll,
      fetchMissionData,
      selectedMissionYear: selectedMissionYear as Ref<number>,
      missionProgressCardData,
      checkPointListByArea,
      handleCheckInClicked,
      hideCheckInFailedDetailModal,
      checkInMissionsBeingHeld: checkInMissionsBeingHeld as Ref<Array<MissionProgressDocument>>,
      checkInMissionsBeingHeldForThisSeason: checkInMissionsBeingHeldForThisSeason as Ref<
        Array<MissionProgressDocument>
      >,
      successfulCheckIn,
      isShowCheckInFailedDetailModal,
    }
  },
})
