<template>
  <div class="membership-point-expiration-parts" v-if="isIncludeNextRelease">
    <p class="membership-point-expiration-parts__information">
      <span class="membership-point-expiration-parts__point">{{ point.toLocaleString() }}P</span>
      <span class="membership-point-expiration-parts__date"
        >：{{ $tc('MypagePage.MypageTopPage.point.expirationDate') }} {{ expirationDate }}</span
      >
    </p>
    <p class="membership-point-expiration-parts__detail">
      <router-link
        to="/mypage/point-expiration-date"
        class="membership-point-expiration-parts__detail-link"
        >{{ $tc('MypagePage.MypageTopPage.point.linkText') }}</router-link
      >
    </p>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'MembershipPointExpirationParts',
  setup() {
    const point = ref(1400)
    const expirationDate = ref('2025/01/31')
    // TODO: ポイント有効期限表示対応時に消す
    const isIncludeNextRelease = process.env.VUE_APP_INCLUDE_NEXT_RELEASE === 'true'
    return {
      point,
      expirationDate,
      isIncludeNextRelease,
    }
  },
})
</script>

<style scoped lang="scss">
.membership-point-expiration-parts {
  display: flex;
  gap: 20px;
  align-items: center;

  &__point {
    font-size: 11px;
    font-weight: 600;
    color: $white;
    letter-spacing: 0.04em;
  }

  &__date {
    margin-left: 3px;
    font-family: $fontSignup;
    font-size: 10px;
    font-weight: 600;
    color: $white;
    letter-spacing: 0.04em;
  }

  &__detail {
    &-link {
      font-size: 10px;
      font-weight: 700;
      color: $white70;
      letter-spacing: 0.016em;
      text-decoration: underline;
    }
  }
}
</style>
