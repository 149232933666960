import { computed, reactive } from '@vue/composition-api'
import Logger from '@/util/logger/Logger'
import DeviceInfo from '@/util/DeviceInfo'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import UserStore from '@/store/stores/pageStore/common/UserStore'

/**
 * デバイスプラットフォームの型
 */
type PlatformType = CdvPurchase.Platform.APPLE_APPSTORE | CdvPurchase.Platform.GOOGLE_PLAY

/**
 * アプリ内課金 表示用商品情報の型
 */
export type InAppPurchaseSubscriptionType = {
  annual: CdvPurchase.Product | undefined
  monthly: CdvPurchase.Product | undefined
}

/**
 * アプリ内課金処理のHook
 */
export default function useInAppPurchase() {
  const mypagePageStore = StoreUtil.useStore('MypagePageStore')
  const { store, ProductType, Platform } = CdvPurchase

  const state = reactive({
    // 定期購入商品情報
    subscriptions: {
      annual: undefined,
      monthly: undefined,
    } as InAppPurchaseSubscriptionType,
    // 対象のプラットフォーム（iOS または Android）
    targetPlatform: undefined as PlatformType | undefined,
    // 年額プラン定期購入商品の製品ID
    annualSubscriptionProductId: '',
    // 月額プラン定期購入商品の製品ID
    monthlySubscriptionProductId: '',
  })

  /** プラットフォーム */
  if (DeviceInfo.isiOS()) {
    // iOS
    state.targetPlatform = Platform.APPLE_APPSTORE
    state.annualSubscriptionProductId = Const.IN_APP_PURCHASE_SUBSC_PRODUCT_ID.IOS.ANNUAL
    state.monthlySubscriptionProductId = Const.IN_APP_PURCHASE_SUBSC_PRODUCT_ID.IOS.MONTHLY
  }
  if (DeviceInfo.isAndroid()) {
    // Android
    state.targetPlatform = Platform.GOOGLE_PLAY
    state.annualSubscriptionProductId = Const.IN_APP_PURCHASE_SUBSC_PRODUCT_ID.ANDROID.ANNUAL
    state.monthlySubscriptionProductId = Const.IN_APP_PURCHASE_SUBSC_PRODUCT_ID.ANDROID.MONTHLY
  }

  /** SFgo定期購入商品 */
  const sfgoSubscriptions = computed({
    get: () => <InAppPurchaseSubscriptionType>state.subscriptions,
    set: (val: InAppPurchaseSubscriptionType) => {
      state.subscriptions = val
    },
  })

  /**
   * restore関数を無効化
   */
  const storekit = Object.getPrototypeOf(store)
  storekit.originalRestore = storekit.restore
  if (DeviceInfo.isiOS() && localStorage) {
    /**
     * StoreKit(iOS)がある場合、refresh時のrestoreを防ぐため、restore関数を無効化する
     * https://qiita.com/s-suefusa/items/8da126510d0263dd1e60#ios%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88%E3%82%A2%E3%81%8C%E5%8E%84%E4%BB%8Bfor-ios
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    storekit.restore = () => {}
  }

  /**
   * UIをリフレッシュ
   */
  const refreshUI = () => {
    const annualSubscription = store.get(state.annualSubscriptionProductId, state.targetPlatform)
    const monthlySubscription = store.get(state.monthlySubscriptionProductId, state.targetPlatform)

    if (annualSubscription) {
      // 年額プランの商品情報をセット
      sfgoSubscriptions.value.annual = annualSubscription
    }
    if (monthlySubscription) {
      // 月額プランの商品情報をセット
      sfgoSubscriptions.value.monthly = monthlySubscription
    }
  }

  /**
   * 商品を購入
   */
  const purchaseSubscription = async (productId: string) => {
    const product = store.get(productId, state.targetPlatform)

    // 組織情報をサーバー通知で受け取れるようにする
    let additionalData
    const organizationId = UserStore.value.user.value._organization ?? ''
    const userId = UserStore.value.user.value.id ?? ''
    if (DeviceInfo.isiOS()) {
      store.applicationUsername = organizationId
    } else if (DeviceInfo.isAndroid()) {
      additionalData = {
        googlePlay: {
          accountId: organizationId,
          profileId: userId,
        },
      }
    }

    return product
      ?.getOffer()
      ?.order(additionalData)
      .then((error) => {
        if (error) {
          Logger.error(
            `useInAppPurchase#purchaseSubscription: error code: ${error?.code}. error message: ${error?.message}`,
          )
          return { isSuccess: false }
        }
        return { isSuccess: true }
      })
  }

  /**
   * 購入完了処理（iOS）
   * NOTE: 商品購入時、リストア時にfinishPurchaseが複数回呼ばれてしまうため、このメソッド内で契約情報の更新処理を呼ばないようにしている
   */
  const finishPurchase = async (transaction: CdvPurchase.Transaction) => {
    // iOSの場合transactionIdをセット
    mypagePageStore.purchaseSubscriptionUuid.value = transaction.transactionId

    // iOSの場合、購入完了後にトランザクションを閉じる
    await transaction.finish()

    if (localStorage) {
      /**
       * iOSのローカルストレージのレシート情報は使用しないため破棄する
       * https://qiita.com/s-suefusa/items/8da126510d0263dd1e60#%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8%E3%81%8C%E3%83%91%E3%83%B3%E3%82%AF%E3%81%99%E3%82%8Bfor-ios
       */
      localStorage.removeItem('sk_receiptForProduct')
      localStorage.removeItem('sk_receiptForTransaction')
      localStorage.removeItem('sk_appStoreReceipt')
    }
  }

  /**
   * 購入完了処理（Android）
   * NOTE: 商品購入時、リストア時にfinishPurchaseが複数回呼ばれてしまうため、このメソッド内で契約情報の更新処理を呼ばないようにしている
   */
  const prepareFinishPurchase = async (transaction: CdvPurchase.Transaction) => {
    // Androidの場合purchaseId（=purchaseToken）をセット
    mypagePageStore.purchaseSubscriptionUuid.value = transaction.purchaseId ?? ''

    // アプリ内課金の通知を受け取った後にfinish処理を実行するためするため、トランザクション情報をストアに保存
    mypagePageStore.inAppPurchaseTransaction.value = transaction
  }

  /**
   * リストア処理
   */
  const restore = async () =>
    // finishPurchaseが実行され、プラン情報が更新される
    store.restorePurchases().then(() => {
      Logger.info(`useInAppPurchase#restore: purchase has been restored.`)
    })

  /**
   * 表示する商品情報データを設定
   */
  const setDisplaySubscriptionData = async () => {
    // 購入ステータスを更新
    await store.update()
    // UIを更新
    refreshUI()
  }

  /**
   * 初期化処理
   */
  const initInAppPurchase = async () => {
    if (state.targetPlatform) {
      store.register([
        {
          id: state.annualSubscriptionProductId,
          type: ProductType.PAID_SUBSCRIPTION,
          platform: state.targetPlatform,
        },
        {
          id: state.monthlySubscriptionProductId,
          type: ProductType.PAID_SUBSCRIPTION,
          platform: state.targetPlatform,
        },
      ])
    }

    await store.initialize([Platform.GOOGLE_PLAY, Platform.APPLE_APPSTORE])
    // 購入ステータスを更新
    await store.update()
    store.verbosity = CdvPurchase.LogLevel.DEBUG

    refreshUI()

    /**
     * エラーハンドラを登録
     */
    store.error((error) => {
      Logger.error(
        `useInAppPurchase#onDeviceReady: error code: ${error.code}. error message: ${error.message}`,
      )
      if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
        // 購入をキャンセルした場合もエラー処理が発火する。キャンセルした場合はエラーメッセージを表示する必要がないと思うため、表示しないようにした。
        return
      }
      MessageDialogStore.value.open({
        title: I18n.tc(
          'MypagePage.MypageSwitchToPaidPlanPage.errors.inAppPurchaseUnexpectedError.title',
        ),
        message: I18n.t(
          'MypagePage.MypageSwitchToPaidPlanPage.errors.inAppPurchaseUnexpectedError.message',
          {
            errorCode: error.code,
            errorMessage: error.message,
          },
        ).toString(),
      })
    })

    /**
     * 商品にコールバックを設定
     */
    store
      .when()
      .productUpdated(refreshUI) // 商品に変更があった
      .approved((receipt) => {
        // 商品が承認済みになった
        if (DeviceInfo.isiOS()) {
          return finishPurchase(receipt)
        }
        if (DeviceInfo.isAndroid()) {
          return prepareFinishPurchase(receipt)
        }
        return undefined
      })
  }

  return {
    store,
    sfgoSubscriptions,
    purchaseSubscription,
    restore,
    setDisplaySubscriptionData,
    initInAppPurchase,
  }
}
