<template>
  <div class="mypage-blocked-user-list-item">
    <div class="mypage-blocked-user-list-item__avatar">
      <img src="@/assets/img/CanselPaidPlanPage/unusable01.jpg" alt="" />
    </div>
    <div class="mypage-blocked-user-list-item__main">
      <p
        class="mypage-blocked-user-list-item__type"
        :class="`mypage-blocked-user-list-item__type--${user.type}`"
      >
        {{ roleLabels[user.type] }}
      </p>
      <p class="mypage-blocked-user-list-item__name">{{ user.name }}</p>
    </div>
    <div class="mypage-blocked-user-list-item__tool">
      <button
        class="mypage-blocked-user-list-item__button"
        :class="{ 'mypage-blocked-user-list-item__button--cancel': !isBlocking }"
        @click="handleClicked"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import I18n from '@/locales/I18n'
import Const from '@/util/Const'

export default defineComponent({
  name: 'MypageBlockedUserItemSection',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // ブロック中かどうか
    const isBlocking = ref(true)

    // ボタンのラベルを返す
    const buttonLabel = computed(() =>
      isBlocking.value
        ? I18n.tc('MypagePage.MypageBlockedUserListPage.buttonLabel.blocking')
        : I18n.tc('MypagePage.MypageBlockedUserListPage.buttonLabel.block'),
    )

    /**
     * 「ブロック中」ボタンが押下されたときの処理
     */
    const handleClicked = () => {
      isBlocking.value = !isBlocking.value
    }

    return {
      roleLabels: Const.SFGO_USER_TYPE_LABELS,
      isBlocking,
      buttonLabel,
      handleClicked,
    }
  },
})
</script>

<style scoped lang="scss">
.mypage-blocked-user-list-item {
  display: flex;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding: 12px 0;

  &__avatar {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__main {
    flex-grow: 1;
    padding-left: 10px;
    overflow: hidden;
  }

  &__type {
    display: inline-block;
    padding: 2px 4px;
    font-size: 11px;
    font-weight: 700;
    color: $black;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    background-color: #e2e0ff;
    border-radius: 2px;

    &--paid-user {
      background-color: #e2e0ff;
    }

    &--free-user {
      background-color: #e2e0ff;
    }

    &--ambassador,
    &--adviser,
    &--special-supporter {
      background-color: #ff4949;
    }

    &--jrp-staff {
      background-color: #4fafff;
    }

    &--driver {
      background-color: #20cabd;
    }

    &--team-user,
    &--team-engineer {
      background-color: #53e2e2;
    }

    &--guest {
      background-color: #ffbb00;
    }
  }

  &__name {
    overflow: hidden;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.54;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__tool {
    flex-shrink: 0;
    width: 96px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 40px;
    font-size: 13px;
    font-weight: 700;
    color: $black;
    letter-spacing: 0.04em;
    background-color: $red-light;
    border: 1px solid rgba($black, 0.2);
    border-radius: 4px;

    &--cancel {
      color: $gray75;
      background-color: $white;
    }
  }
}
</style>
