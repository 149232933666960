











































import { defineComponent, Ref } from '@vue/composition-api'
import MypageEcSiteConnectErrorParts from '@/components/MypageEcSiteConnectPage/MypageEcSitePane/parts/MypageEcSiteConnectErrorParts.vue'
import MypageEcSiteConnectCompleteParts from '@/components/MypageEcSiteConnectPage/MypageEcSitePane/parts/MypageEcSiteConnectCompleteParts.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import MypageEcSiteConnectButtonParts from '@/components/MypageEcSiteConnectPage/MypageEcSitePane/parts/MypageEcSiteConnectButtonParts.vue'
import StoreUtil from '@/store/StoreUtil'
import type { EcConnectStatusType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import Const from '@/util/Const'
import useEcErrorHandling from '@/components/MypageEcSiteConnectPage/hook/useEcErrorHandling'

export default defineComponent({
  name: 'MypageEcSiteConnectSection',
  components: {
    MypageEcSiteConnectButtonParts,
    AtomIconLinkButton,
    MypageEcSiteConnectCompleteParts,
    MypageEcSiteConnectErrorParts,
  },
  props: {
    isConnectedBrowsingThisPage: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { ecConnectStatus, isEcConnectError } = mypagePageStore
    const { ecConnectErrorData } = useEcErrorHandling()

    // ECサイト会員登録ページURL
    const ecSiteRegistrationUrl = Const.EXTERNAL_LINKS.EC_SITE.REGISTRATION
    // ECサイトメインページURL
    const ecSiteUrl = Const.EXTERNAL_LINKS.EC_SITE.TOP

    return {
      // MypageEcSiteConnectSection
      ecSiteRegistrationUrl,
      ecSiteUrl,
      // useEcErrorHandling
      ecConnectErrorData,
      // MypagePageStore
      ecConnectStatus: ecConnectStatus as Ref<EcConnectStatusType>,
      isEcConnectError: isEcConnectError as Ref<boolean>,
    }
  },
  methods: {
    connectEc() {
      this.$emit('connectEc')
    },
  },
})
