


















import { defineComponent } from '@vue/composition-api'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import ModalCouponPlanCardParts from '@/components/common/modal/parts/ModalCouponPlanCardParts.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

export default defineComponent({
  name: 'OneTimePassRegisterSection',
  components: { AtomButton, ModalCouponPlanCardParts, ModalMessageParts, ModalTitleParts },
  setup(props, { emit }) {
    // TODO: ダミーのクーポンデータ
    const couponData = {
      title: '太田格之進選手応援クーポン',
      subtitle: '太田格之進選手応援クーポン',
      description: '2024年12月31日まで有料コンテンツを無料でご視聴いただけます。',
      period: '2022/7/30〜2022/8/29',
      price: 0,
    }

    const handleRegistrationClicked = () => {
      emit('onClickRegistration')
    }
    return {
      couponData,
      handleRegistrationClicked,
    }
  },
})
