import { computed, reactive } from '@vue/composition-api'
import { sortBy, uniq } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import HighlightDocument from '@/store/stores/collectionModule/documents/highlight/HighlightDocument'
import LocalCachePreLoadItemType from '@/util/localcache/LocalCachePreLoadItemType'
import ContentsInfoDocument from '@/store/stores/collectionModule/documents/contents/ContentsInfoDocument'
import AngleMovieInfoDocument from '@/store/stores/collectionModule/documents/angleMovie/AngleMovieInfoDocument'
import Logger from '@/util/logger/Logger'
import Const from '@/util/Const'
import { VideoPlayerStatusType, VideoStatusType } from '@/components/hook/useVideoPlayer'
import IVideoPlayer from '@/util/videoplayer/IVideoPlayer'

/**
 * 動画再生画面のハイライト画面の初期状態
 */
const raceVideoPageHighlightInitialState = {
  /** ハイライト画面の選手フィルタで選択されている選手のID */
  selectedPlayerMasterIdForHighlight: null as string | null,
  /** ハイライト画面で選択されているハイライト情報のID */
  selectedHighlightId: null as string | null,
  /**
   * ハイライト選択後の処理を指定する。
   * - selecting: ハイライトを選択し、そのハイライトを再生する
   * - selectAndSeeking: ハイライトを選択し、シーク処理を行ってから再生する
   */
  highlightSelectionStatus: null as 'selecting' | 'selectAndSeeking' | null,
  /** 動画再生状態 */
  videoStatus: null as VideoStatusType | null,
  /** 動画プレーヤー状態 */
  videoPlayerStatus: null as VideoPlayerStatusType | null,
  /** 動画プレーヤーのインスタンス */
  videoPlayer: null as IVideoPlayer | null,
  /** 動画プレーヤーの変更をスキップするかどうか */
  skipChangeVideoPlayer: false,
}
/**
 * レース動画再生画面のハイライト情報を操作するための処理を取得する。
 */
export default function useHighlightData() {
  // Collection modules
  const highlightCollectionModule = CollectionModule.createStore(HighlightDocument)
  const highlightPublicCollectionModule = CollectionModule.createStore(HighlightDocument)

  /**
   * ハイライト画面の状態
   */
  const raceVideoPageHighlightState = reactive({ ...raceVideoPageHighlightInitialState })

  /**
   * 公式ハイライトの情報を取得する
   * @param matchId ハイライト情報を取得する対象のレースのID
   */
  const fetchOfficialHighlights = (matchId: string) =>
    highlightCollectionModule.fetch({
      query: {
        filter: { matchId, _organization: process.env.VUE_APP_SFGO_PARENT_ORG_ID },
      },
    })

  /**
   * isPublicの値に応じてハイライト情報一覧を取得するか共有されたハイライト情報一覧を取得するか判断して実行する。
   * @param filterMatchId ハイライト情報を取得する対象のレースのID
   * @param isPublic ハイライト情報一覧を取得するか共有されたハイライト情報一覧を取得するかを設定する
   * @param lastModifiedDate 前回のハイライト情報取得日時。指定した場合、この日時以降に登録、更新、削除されたデータのみを再取得する
   */
  const fetchHighlights = (filterMatchId: string, isPublic: boolean, lastModifiedDate?: number) => {
    const filter = {
      matchId: filterMatchId,
    } as {
      matchId: string
      _lastModifiedDate: {
        $gte: number
      }
      'publicScope.parentOrganization'?: {
        $ne: string
      }
    }
    if (!isPublic)
      // publicScope.parentOrganization：publicScopeを設定しているハイライトは、isPublicをtrueにしたときに取得されるのでfalseのときには含めないようにする
      filter['publicScope.parentOrganization'] = {
        $ne: process.env.VUE_APP_SFGO_PARENT_ORG_ID,
      }
    if (lastModifiedDate) {
      // 最後にハイライト情報を取得した日時以降に追加・更新されたハイライト情報のみを取得する
      // データ取りこぼしを防ぐため、ポーリング間隔分マージンをつける
      filter._lastModifiedDate = { $gte: lastModifiedDate - Const.HIGHLIGHT_POLING_INTERVAL }
    }

    const options = {
      query: {
        filter,
        sort: 'movieStartTime',
        expand: 'angle,communication,thumbnail',
      },
      isUnionExistData: !!lastModifiedDate,
      includeDeleted: true,
      includePublicScope: isPublic,
    }

    return !isPublic
      ? highlightCollectionModule.fetch(options)
      : highlightPublicCollectionModule.fetch(options)
  }

  /**
   * 新着ハイライト数を取得する
   * @param filterMatchId ハイライト情報を取得する対象のレースのID
   * @param isPublic ハイライト情報一覧を取得するか共有されたハイライト情報一覧を取得するかを設定する
   * @param lastModifiedDate 前回のハイライト情報取得日時。指定した場合、この日時以降に登録、更新、削除されたデータのみを再取得する
   */
  const fetchNewHighlightCount = (
    filterMatchId: string,
    isPublic: boolean,
    lastModifiedDate?: number,
  ) => {
    const filter = {
      matchId: filterMatchId,
      _createdDate: {
        $gte:
          lastModifiedDate && lastModifiedDate > Const.HIGHLIGHT_POLING_INTERVAL
            ? lastModifiedDate - Const.HIGHLIGHT_POLING_INTERVAL
            : 0,
      },
    } as {
      matchId: string
      _createdDate: {
        $gte: number
      }
      'publicScope.parentOrganization'?: {
        $ne: string
      }
    }
    if (!isPublic) {
      // publicScope.parentOrganization：publicScopeを設定しているハイライトは、isPublicをtrueにしたときに取得されるのでfalseのときには含めないようにする
      filter['publicScope.parentOrganization'] = {
        $ne: process.env.VUE_APP_SFGO_PARENT_ORG_ID,
      }
    }

    const options = {
      query: {
        filter,
        sort: '-_createdDate',
        count: true,
      },
      isSaveInStore: false,
      includePublicScope: isPublic,
    }

    return !isPublic
      ? highlightCollectionModule.fetch(options)
      : highlightPublicCollectionModule.fetch(options)
  }

  /**
   * 共有された新着ハイライト情報一覧を取得する。
   */
  const fetchRecentPublicHighlights = (limit?: number) =>
    highlightPublicCollectionModule.fetch({
      query: {
        limit: limit ?? 10,
        sort: '-_createdDate',
        expand: 'angle,communication,thumbnail',
      },
      includeDeleted: false,
      includePublicScope: true,
    })

  /**
   * ハイライトの削除
   */
  const deleteHighlight = (highlightId: string) =>
    highlightCollectionModule.remove(highlightId, {
      isSoftDelete: true,
    })

  /**
   * ハイライトの作成・編集
   */
  const saveHighlight = (requestData: HighlightDocument) =>
    highlightCollectionModule.save(requestData)

  /**
   * 対象IDにマッチするハイライト情報を取得する
   * - １つ目のfetch：親組織の作成したハイライト情報を取得
   * - ２つ目のfetch：共有されたハイライト情報を取得
   */
  const fetchHighlightsByIds = (
    highlightIds: string[],
    isPublic: boolean,
    isUnionExistData: boolean,
  ) => {
    const filter = {
      userGameEventId: { $in: highlightIds },
    } as {
      userGameEventId: { $in: string[] }
      'publicScope.parentOrganization'?: {
        $ne: string
      }
    }
    if (!isPublic)
      // publicScope.parentOrganization：publicScopeを設定しているハイライトは、isPublicをtrueにしたときに取得されるのでfasleのときには含めないようにする
      filter['publicScope.parentOrganization'] = {
        $ne: process.env.VUE_APP_SFGO_PARENT_ORG_ID,
      }

    const options = {
      query: {
        filter,
        expand: 'angle,communication,thumbnail',
      },
      isUnionExistData,
      includePublicScope: isPublic,
    }

    return !isPublic
      ? highlightCollectionModule.fetch(options)
      : highlightPublicCollectionModule.fetch(options)
  }

  /**
   * 対象のレースIDにマッチするハイライト情報を取得する
   */
  const fetchHighlightsByRaceIds = (raceIds: string[]) =>
    highlightCollectionModule.fetch({
      query: {
        filter: { matchId: { $in: raceIds } },
      },
      isSaveInStore: false,
    })

  /**
   * 取得したハイライト情報一覧
   */
  const highlights = computed(() => {
    const blockedUserIds =
      ContractInfoStore.value.ownOrganization.value?.communication?.blockedUserIds ?? []

    // ブロックしたユーザーが作成したハイライトを除外する
    return [...highlightCollectionModule.data, ...highlightPublicCollectionModule.data].filter(
      (highlight) => !highlight.deleted && !blockedUserIds.includes(highlight._createdBy ?? ''),
    )
  })

  /**
   * 取得したハイライト情報一覧を、movieStartTimeでソートしたデータ
   */
  const highlightsSortByMovieStartTime = computed(() => sortBy(highlights.value, 'movieStartTime'))

  /**
   * 取得したハイライト情報からスタートイベントだけの一覧
   */
  const startEvents = computed(() =>
    highlights.value.filter((highlight) => highlight.playEventId === 'playStart'),
  )
  /**
   * 取得したハイライトのユーザーID配列
   */
  const highlightUserIds = computed(() =>
    uniq(
      highlights.value.map((v: HighlightDocument) => v._createdBy || '').filter((v) => v !== ''),
    ),
  )
  /**
   * 取得したハイライト情報一覧。
   * IDをキーにしてハイライト情報が値に設定されたマップオブジェクト
   */
  const highlightsById = computed(() => ({
    ...highlightCollectionModule.dataMap.value,
    ...highlightPublicCollectionModule.dataMap.value,
  }))

  /**
   * ハイライト情報一覧をローカルキャッシュに指定可能な形式に変換する。
   * @param highlightDataSet ハイライト情報一覧
   * @param raceMovieInfo レースの動画情報
   */
  const highlightsForPreLoad = (
    highlightDataSet: Array<HighlightDocument>,
    raceMovieInfo?: ContentsInfoDocument | AngleMovieInfoDocument,
  ) => {
    const preLoadItems: Array<LocalCachePreLoadItemType> = []
    highlightDataSet.forEach((highlight) => {
      const playListPath = highlight.playlistPath
      if (playListPath) {
        const preLoadItem = {
          video_m3u8: playListPath,
          segment_path: playListPath.substring(0, playListPath.lastIndexOf('/') + 1),
          time_sec: (highlight.movieStartTime || 0) + (highlight.offsetStart || 0),
          isLive: raceMovieInfo ? raceMovieInfo.isLive : true,
        }
        preLoadItems.push(preLoadItem)
        Logger.debug(
          `highlightsForPreLoad: Preload item has been created. data: ${JSON.stringify(
            preLoadItem,
          )}`,
        )
      }
    })
    return preLoadItems
  }

  /**
   * ハイライト一覧で選択されているハイライト情報のID
   */
  const selectedHighlightId = computed({
    get: (): string | null => {
      Logger.debug(
        `RaceVideoPageStore#raceVideoPageHighlightState.selectedHighlightId: ${raceVideoPageHighlightState.selectedHighlightId}`,
      )
      return raceVideoPageHighlightState.selectedHighlightId
    },
    set: (highlightId: string | null) => {
      // ハイライトの状態に応じて、ハイライト選択後の処理を指定する
      if (raceVideoPageHighlightState.selectedHighlightId === highlightId) {
        // ハイライトの選択状態がハイライトを再生する
        raceVideoPageHighlightState.highlightSelectionStatus = 'selecting'
      } else if (highlightId) {
        // ハイライトの再生位置までシークして再生する
        raceVideoPageHighlightState.highlightSelectionStatus = 'selectAndSeeking'
      } else {
        // ハイライトIDがnullに設定された場合、何もしない
        raceVideoPageHighlightState.highlightSelectionStatus = null
      }

      raceVideoPageHighlightState.selectedHighlightId = highlightId
    },
  })

  /**
   * ハイライト画面で選択されているハイライト情報
   */
  const selectedHighlight = computed(() => {
    if (raceVideoPageHighlightState.selectedHighlightId) {
      return highlightsById.value[raceVideoPageHighlightState.selectedHighlightId]
    }
    return undefined
  })

  /**
   * 動画プレーヤーのインスタンスを設定する
   * @param videoPlayer 動画プレーヤー
   */
  const setVideoPlayer = (videoPlayer: IVideoPlayer) => {
    raceVideoPageHighlightState.videoPlayer = videoPlayer
  }

  /**
   * 動画状態を設定する
   * @param videoStatus 動画状態
   */
  const setVideoStatus = (videoStatus: VideoStatusType) => {
    raceVideoPageHighlightState.videoStatus = videoStatus
  }
  /**
   * 動画プレーヤー状態を設定する
   * @param videoPlayerStatus 動画プレーヤー状態
   */
  const setVideoPlayerStatus = (videoPlayerStatus: VideoPlayerStatusType) => {
    raceVideoPageHighlightState.videoPlayerStatus = videoPlayerStatus
  }

  /**
   * 取得したハイライト情報をクリアする
   */
  const clearHighlight = () => {
    highlightCollectionModule.clearData()
    highlightPublicCollectionModule.clearData()
    Object.assign(raceVideoPageHighlightState, raceVideoPageHighlightInitialState)
  }

  return {
    fetchHighlights,
    fetchNewHighlightCount,
    fetchRecentPublicHighlights,
    fetchHighlightsByIds,
    fetchHighlightsByRaceIds,
    saveHighlight,
    fetchOfficialHighlights,
    highlights,
    highlightUserIds,
    highlightsById,
    highlightsForPreLoad,
    clearHighlight,
    selectedHighlightId,
    selectedHighlight,
    raceVideoPageHighlightState,
    deleteHighlight,
    startEvents,
    highlightsSortByMovieStartTime,
    setVideoPlayer,
    setVideoStatus,
    setVideoPlayerStatus,
  }
}
