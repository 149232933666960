















import { defineComponent } from '@vue/composition-api'
import MypagePointItemParts from '@/components/MypagePointHistoryPage/MypagePointHistoryPane/parts/MypagePointItemParts.vue'

export default defineComponent({
  name: 'MypagePointMonthlySection',
  components: { MypagePointItemParts },
  props: {
    monthlyData: {
      type: Object,
    },
  },
})
