













import { defineComponent, PropType } from '@vue/composition-api'
import MypageEcSiteConnectContactParts from '@/components/MypageEcSiteConnectPage/MypageEcSitePane/parts/MypageEcSiteConnectContactParts.vue'

interface ErrorData {
  // タイトル
  title: string | null
  // 説明
  description: string
  // 問い合わせフォームを表示するかどうか
  hasContact: boolean
}

export default defineComponent({
  name: 'MypageEcSiteConnectErrorParts',
  components: { MypageEcSiteConnectContactParts },
  props: {
    errorData: {
      type: Object as PropType<ErrorData>,
      required: true,
    },
  },
})
