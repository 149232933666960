
















import { defineComponent } from '@vue/composition-api'
import FieldsetTextParts from '@/components/common/form/FieldsetTextParts.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

export default defineComponent({
  name: 'ModalCouponCodeInputParts',
  components: { AtomButton, FieldsetTextParts },
  props: {
    buttonLabel: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const handleUseClicked = () => {
      emit('onCLickUse')
    }

    return {
      handleUseClicked,
    }
  },
})
