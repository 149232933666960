<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <path
      d="M3.66669 4.33366V7.33366H4.33335V4.33366H7.33335V3.66699H4.33335V0.666992H3.66669V3.66699H0.666687V4.33366H3.66669Z"
      fill="#2B2B2B"
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MypagePointPlusIcon',
})
</script>
